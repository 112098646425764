import React from 'react'
import Select from 'react-select';
import './MOESelect.scss';

const MOESelect = ({ ...rest }) => {
  return (
    <div className="moe-react-select">
      <Select
        classNamePrefix="moe-react-select"
        // Disabled search only for 'mobileWebView' source channel
        isSearchable={(localStorage.getItem('sourceChannel') != 'mobileWebView')}
        isRtl={isArabic}
        {...rest}

      />
    </div>
  )
}

export default MOESelect