/* eslint-disable import/no-unused-modules */
/* eslint-disable eqeqeq */
import axios from "axios";
import {servicesCategoriesIcon} from "../../constants/servicesCategoriesIcon";
import {getServicesCards, getServicesCategories} from "../../api/services.api";

export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
export const GET_ALL_SERVICES_ERROR = 'GET_ALL_SERVICES_ERROR';

export const GET_SERVICES_CATEGORIES = 'GET_SERVICES_CATEGORIES';
export const GET_SERVICES_CATEGORIES_ERROR = 'GET_SERVICES_CATEGORIES_ERROR';

export const GET_SINGLE_SERVICE = 'GET_SINGLE_SERVICE';
export const GET_FILTERED_CATEGORIES = 'GET_FILTERED_CATEGORIES';
export const GET_SEARCHED_SERVICES = 'GET_SEARCHED_SERVICES';
export const SET_SELECTED_FILTER_ACTIVE = 'SET_SELECTED_FILTER_ACTIVE';
export const SET_FILTERING_ITEMS_BY = 'SET_FILTERING_ITEMS_BY';
export const SET_NABO_SERVICE_MODAL = 'SET_NABO_SERVICE_MODAL';

//==============================================
// Fetch Data From API, Then Set it to Store
//===============================================
export const fetchServiceData = () => async (dispatch) => {

    try {
        await handelServicesAPI(dispatch);
        await handelCategoriesAPI(dispatch);
    }
    catch (err) {
        console.log(err)
    }

    const urlParam = handelingURLParams('categoryID') || null;
    if (urlParam) {
        dispatch({type: GET_FILTERED_CATEGORIES, payload: urlParam})
    }

}

//===================================================================================================
// 1- get the API Result using axios, then set it to result variable
//===================================================================================================
export const handelServicesAPI = () => async (dispatch) => {
    try {
        // 1- get the API Result using axios, then set it to result variable
        let result = await getServicesCards();
        result = result.data.value.filter(item => item.isESE.toLowerCase() == "no").map(item => ({...item, show: true}))
        dispatch({type: GET_ALL_SERVICES, payload: result})
    } catch (err) {
        //setServicesError(err);
        dispatch({type: GET_ALL_SERVICES_ERROR, payload: isArabic ? 'حدث خطأ أثناء تحميل البيانات' : 'Error while loading data'})
    }
}
//===================================================================================================
// 2- Get Categories List from API
//===================================================================================================
export const handelCategoriesAPI = () => async (dispatch) => {
    try {
        // Use Axios to get Categories List from API
        let result = await getServicesCategories();
        // Get Categories List from API
        result = result.data.value;
        // Append {icon , order} to categories list
        result = result.map(item => {
            const {ID} = item;
            return {
                ...item,
                icon: servicesCategoriesIcon.find(item => item.ID === ID)?.icon,
                order: servicesCategoriesIcon.find(item => item.ID === ID)?.order,
                active: false
            }
        })
        // Sort Categories List by order
        result = result.sort((a, b) => a.order - b.order)
        // Save Categories List to [Redux Store]
        dispatch({type: GET_SERVICES_CATEGORIES, payload: result})
    }
    catch (err) {
        // Set Categories Error Text
        const errorText = isArabic ? 'حدث خطأ أثناء تحميل البيانات' : 'Error while loading data';
        // Set Categories Error Text to [Redux Store]
        dispatch({type: GET_SERVICES_CATEGORIES_ERROR, payload: errorText})
        // Print Actual Error in Console
        console.log("Error in categoriesAPI: ", err)
    }
}

export const handelSearchServices = (e) => async (dispatch) => {
    const keyword = e.target.value;
    dispatch({type: GET_SEARCHED_SERVICES, payload: keyword});
}