let $ = jQuery;

window.onload = function () {
    // Re Apply the custom css to the live chat after the page has loaded
    if (typeof cxone !== 'undefined' && typeof getColorTheme !== 'undefined' && typeof checkDarkMode !== 'undefined' && typeof liveChatStyle !== 'undefined') {
        cxone('chat', "setCustomCss", liveChatStyle(getColorTheme(), checkDarkMode()));
    } else {
        console.log('cxone is not defined');
    }


    // Your code to run after the entire page has loaded

    var lang = "/ar";

    let exitSite = "";
    if (window._spPageContextInfo.currentLanguage === 1025) {
        lang = "/ar";
        exitSite =
            "يوجهك هذا الرابط إلى موقع خارجي قد تكون له سياسات مختلفة للمحتوى والخصوصية عن موقع وزارة التعليم العالي والبحث العلمي";
        $("#ReadSpeakerBtn").attr(
            "href",
            "//app-as.readspeaker.com/cgi-bin/rsent?customerid=5751&amp;lang=ar_ar&amp;readid=content-block,page-title,readspeaker-content&amp;url="
        );
    } else {
        lang = "/en";
        exitSite =
            "This link will direct you to an external website that may have different content and privacy policies than MOHESR website";
        $("#ReadSpeakerBtn").attr(
            "href",
            "//app-as.readspeaker.com/cgi-bin/rsent?customerid=5751&amp;lang=en_uk&amp;readid=content-block,page-title,readspeaker-content&amp;url="
        );
    }

    const excludedLinks =
        'a[href]:not([href*="mohesr.gov.ae"]):not([href*="advanced.aspx"]):not([href*="wa.me"]):not([href*="mohesr.gov.ae"]):not([href*="www.youtube.com"]):not([href*="moestgwebwfen01"]):not([href*="moedevwebappn01"]):not([href*="staging.mohesr.gov.ae"]):not([href^="/"]):not([href=""]):not([href^="../"]):not([href^="#"]):not([href^="mailto:"]):not([href^="javascript:"]):not([href^="tel:"]):not([class*="fancybox"]):not([href*="readspeaker.com"])';
    $(excludedLinks).each(function () {
        $(this)
            .attr("data-mdb-toggle", "tooltip")
            .attr("data-mdb-placement", "top")
            .attr("title", exitSite);
    });



    $('[data-mdb-toggle="tooltip"]').tooltip();
    var fourthChild = $(".innerBreadcrumb").children().eq(6);
    if (window._spPageContextInfo.currentLanguage === 1025) {
        if (fourthChild.text() == "بطاقة الخدمة") {
            fourthChild.find("a").text("معرض الخدمات");
        }
    } else if (fourthChild.text() == "ServiceCard") {
        fourthChild.find("a").text("Service Catalog");
    }
    $('a[href$=".pdf"]').attr("target", "_blank");
    let sharepointLoginBtn = $("#ctl00_login_loginLink");
    if (sharepointLoginBtn.length) {
        $("#ctl00_login_loginLink").css("display", "none");
        $("#loginLink,#loginLinkMobile").attr(
            "href",
            $("#ctl00_login_loginLink").attr("href")
        );
    } else {
        let htmlContent = $("#postLoginDiv");
        $("#loginLink,#loginLinkMobile").html(htmlContent);
        $("#postLoginDiv").css("display", "none");
        $("#loginLink #postLoginDiv, #loginLinkMobile #postLoginDiv").css(
            "display",
            "block"
        );
        // $('#loginLink .dropdown .dropdown-toggle').addClass('btn btn-link');
        $(
            "#loginLink .dropdown .dropdown-menu li a,#loginLinkMobile .dropdown .dropdown-menu li a"
        ).addClass("dropdown-item");
        $(
            "#loginLink .dropdown .dropdown-toggle .fa-chevron-down, #loginLinkMobile .dropdown .dropdown-toggle .fa-chevron-down"
        ).css("display", "none");
    }

    $(document).on("click", ".language-switcher", () => {
        changeLanguage();
        console.log("clicked");
    });

    function changeLanguage() {
        let {currentLanguage} = window._spPageContextInfo;
        let lang = currentLanguage === 1025 ? "ar" : "en";
        let newLang = currentLanguage === 1025 ? "en" : "ar";
        if (lang) {
            let url = window.location.href;
            let newUrl = swapLanguageInUrl(url, lang, newLang);

            if (newUrl) {
                window.location.href = newUrl;
            }
        }
    }

    function swapLanguageInUrl(url, lang, newLang) {
        let lowerUrl = url.toLowerCase();
        let langPrefix = `/${lang}/`;
        let newLangPrefix = `/${newLang}/`;

        if (lowerUrl.indexOf(langPrefix) !== -1) {
            return lowerUrl.replace(langPrefix, newLangPrefix); // Remove current language prefix
        }
        return lowerUrl.replace(newLangPrefix, langPrefix); // Add new language prefix

    }

    let link = "";
    window.addEventListener("pageshow", () => {
        $(".services-list #newSearchTxt").val("");
        $(".check-application-content #applicationNumber").val("");
    });

    $(document).on("click", ".top-header-menu #searchBtn", () => {
        event.preventDefault();
        let queryString = $("#newSearchTxt").val();
        if (queryString != "") {
            let location = "";
            isArabic
                ? (location = `/Ar/Pages/search.aspx?k=${queryString}`)
                : (location = `/En/Pages/search.aspx?k=${queryString}`);
            console.log(location);
            $("#newSearchTxt").val("");
            link = location;
            window.location.href = link;

        } else {
            $("#newSearch small").css("display", "block");
        }
    });

    $(document).on("keypress", "#newSearchTxt", function (key) {
        $("#newSearch small").fadeOut();
        if ($(this).is(":focus") && key.which == 13) {
            $("#searchStart").click();
        }
    });

    $(document).on("click", "#searchAudio", () => {
        SpeechRecognition();
    });

    $(document).on("click", "#openWizerd", () => {
        $(".assessment-Modal").modal("show");
    });

    function SpeechRecognition() {
        window.SpeechRecognition =
            window.webkitSpeechRecognition || window.SpeechRecognition;
        if ("SpeechRecognition" in window) {
            // speech recognition API supported
            const recognition = new window.SpeechRecognition();
            recognition.lang = lang;
            let speechToText;
            recognition.onresult = function (event) {
                speechToText = event.results[0][0].transcript;
                $("#newSearchTxt").val(speechToText);
            };
            recognition.start();
        } else {
            // speech recognition API not supported
            alert("Microphone Not supported");
        }
    }

    $(document).on("click", "#print-btn", () => {
        // Print the page
        window.print();
    });

    $(".innerBreadcrumb span:eq(0)").hide();
    $(".innerBreadcrumb span:eq(1)").hide();

    var lang =
        window._spPageContextInfo.currentLanguage === 1025
            ? $(".innerBreadcrumb span:eq(2)").html(
                '<a href="/ar">الصفحة الرئيسية</a>'
            )
            : $(".innerBreadcrumb span:eq(2)").html("<a href='/en'>Home</a>");

    $(document).on("click", "#loginLink", () => {
        $("#loginLink .dropdown,#loginLinkMobile .dropdown").toggleClass("open");
    });

    $(document).on("click", (e) => {
        // Check if the clicked element is inside the dropdown or not
        if (!$(e.target).closest("#loginLink,#loginLinkMobile").length) {
            // If not, close the dropdown
            $("#loginLink .dropdown,#loginLinkMobile .dropdown").removeClass("open");
        }
    });

    $(document).on("click", '[data-toggle="collapse"]', function () {
        $(this).next(".quastionAnswer").toggleClass("collapse");
    });

    var fourthChild = $(".innerBreadcrumb").children().eq(6);
    if (window._spPageContextInfo.currentLanguage === 1025) {
        if (fourthChild.text() == "بطاقة الخدمة") {
            fourthChild.find("a").text("معرض الخدمات");
        }
    } else if (fourthChild.text() == "ServiceCard") {
        fourthChild.find("a").text("Service Catalog");
    }

};
