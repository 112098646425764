import React from 'react'
import MOELayout from '../../layouts/MOELayout'
import { useEffect } from 'react'
import './SearchResultsPage.scss';
import { mdiAccountSearch, mdiSearchWeb } from '@mdi/js';
import Icon from '@mdi/react';

const SearchResultsPage = () => {
    const [loading, setLoading] = React.useState(true)
    const [searchQuery, setSearchQuery] = React.useState('')
    const [searchResults, setSearchResults] = React.useState([])

    useEffect(() => {

        // get url params and search for them
        const urlParams = new URLSearchParams(window.location.search)

        const searchQuery = urlParams.get('q')

        if (searchQuery) {
            setSearchQuery(searchQuery)
            console.log(searchQuery)
        } else {
            setSearchQuery('')
            console.log('no search query')
        }
        setSearchQuery(searchQuery)
        console.log(searchQuery)

    }, [])

    return (
        <MOELayout>
            {
                (searchQuery && searchQuery.length > 0)
                    ? <h1>Search {searchQuery}</h1>
                    : (
                        <div className='search-results-page'>
                            <h1 className='page__title'>Search Results</h1>
                            <div className="card">
                                <div className="card-body">
                                    <Icon path={mdiSearchWeb} title='No search query' size={7} />
                                    <h4 className='no-search-title'>No search query</h4>
                                    <input type='text' className='form-control' placeholder='Search' />
                                    <button className='btn btn-primary' onClick={() => window.location.href = '/'}>Go Home</button>
                                </div>
                            </div>
                        </div>
                    )
            }

        </MOELayout>
    )
}

export default SearchResultsPage