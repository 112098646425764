import { START_LOADING_GET_SERVICE_CARD, SERVICE_CARD_FAIL, SERVICE_CARD_INFO_LIST, SERVICE_CARD_SUCCESS, SERVICE_INFO_BOX } from "../actions/serviceCardAction"

const initialState = {
    pageName: '',
    serviceCard: {},
    infoList: [],
    infoBox: [],
    showModal: false,
    showConfirmationModal: false,
    loading: true,
    error: {},
}

export const serviceCardReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING_GET_SERVICE_CARD:
            return {
                ...state,
                loading: true,
            }
        case SERVICE_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceCard: action.payload,
            }
        case SERVICE_CARD_INFO_LIST:
            return {
                ...state,
                infoList: action.payload
            }
        case SERVICE_INFO_BOX:
            return {
                ...state,
                infoBox: action.payload
            }
        case SERVICE_CARD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}