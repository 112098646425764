import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './MOETitleBanner.scss';

const MOETitleBanner = ({ title, description, image, loading }) => {
    return (
        <>
            {loading ?
                <Skeleton height={300} style={{ borderRadius: '1rem' }} />
                :
                <div className={image ? 'moe-title-banner has-image' : 'moe-title-banner'} style={{ backgroundImage: image ? `url(${image})` : '' }}>
                    {image && <div className="banner-overlay"></div>}
                    <div className="banner-content" >
                        <h3 className='banner-title' dangerouslySetInnerHTML={{ __html: title }}></h3>
                        <p className='banner-description'>
                            {description}
                        </p>
                    </div>
                </div>
            }
        </>
    )
}

export default MOETitleBanner