import { CertificateAttestation, CertificateOfRecognition, EmsatIcon, GeneralIcon, InstitutionalLicensingServicesIcon, ScholarshipIcon, TeacherlicensingServiceIcon } from "../../components/MOEIcon";
import { SEARCH_FAQ, SET_ACTIVE_CATEGORY, SET_ACTIVE_CATEGORY_LOADING, SET_CATEGORIES, SET_FAQS, START_FAQ_LOADING } from "../actions/faqActions";

const initilState = {
    loading: false,
    data: [],
    faqs: [],
    categoriesLoading: false,
    categories: [],
}

export const faqsReducer = (state = initilState, action) => {
    switch (action.type) {
        case START_FAQ_LOADING:
            return {
                ...state,
                loading: true
            }
        case SET_FAQS:
            return {
                ...state,
                data: action.payload,
                faqs: action.payload,
                loading: false
            }
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
                categoriesLoading: false
            }
        case SET_ACTIVE_CATEGORY_LOADING:
            return {
                ...state,
                categoriesLoading: true
            }
        case SET_ACTIVE_CATEGORY:
            return {
                ...state,
                categoriesLoading: false,
                loading: false,
                categories: state.categories.map((item) => {
                    return {
                        ...item,
                        active: item.Id === action.payload ? true : false,
                    }
                })
            }
        case SEARCH_FAQ:
            return {
                ...state,
                faqs: (function () {
                    if (action.payload === '') {
                        return state.data
                    } else {
                        return state.data.filter((item) => {
                            if (item['Title' || 'QuestionAR' || 'AnswerEN' || 'AnswerAr'].toLowerCase().includes(action.payload.toLowerCase())) {
                                return item
                            }
                        })
                    }
                })()
            }
        default:
            return state;
    }
}