import { CLOSE_ACCESSABILITY, TOGGLE_ACCESSABILITY, ACCESSABILITY_CHANGE, ACCESSABILITY_CHANGE_THEME } from "../actions";

const accessabilityTools = {
    isActive: false,
    tools: [
        {
            icon: "fas fa-moon",
            title: "Dark Mode",
            titleAr: "الوضع الليلي",
            id: "dark-mode-switcher",
            type: "checkbox",
            role: "switch",
            checked: false,
            bodyClass: "dark-mode",
        },
        {
            icon: "fas fa-palette",
            title: "Colors",
            titleAr: "الألوان",
            id: "color-switcher",
            type: "radio",
            role: "radio",
            options: [
                {
                    id: "gold",
                    hex: '#FFD700',
                    color: "gold",
                    title: "Gold",
                    titleAr: "ذهبي",
                    active: true,
                },
                {
                    id: "blue",
                    hex: '#0000FF',
                    color: "blue",
                    title: "Blue",
                    titleAr: "أزرق",
                    active: false
                },
                {
                    id: "red",
                    hex: '#FF0000',
                    color: "red",
                    title: "Red",
                    titleAr: "أحمر",
                    active: false
                },
                {
                    id: "green",
                    hex: '#008000',
                    color: "green",
                    title: "Green",
                    titleAr: "أخضر",
                    active: false
                },
            ],
            bodyClass: "color-theme",
        },
        {
            icon: "fas fa-adjust",
            title: "Contrast",
            titleAr: "التباين",
            id: "contrast-switcher",
            type: "checkbox",
            role: "switch",
            checked: false,
        },
        {
            icon: "fas fa-paperclip",
            title: "Highlight",
            titleAr: "التمييز",
            id: "highlight-switcher",
            type: "checkbox",
            role: "switch",
            checked: false,
        },
        {
            icon: "fas fa-text-height",
            title: "Font Size",
            titleAr: "حجم الخط",
            id: "font-size-switcher",
            type: "checkbox",
            role: "switch",
            checked: false,
        },
        {
            icon: "fas fa-mouse-pointer",
            title: "Cursor Size",
            titleAr: "حجم المؤشر",
            id: "cursor-size-switcher",
            type: "radio",
            role: "radio",
            options: [
                {
                    id: "small",
                    size: "small",
                    title: "Small",
                    titleAr: "صغير",
                    active: true,
                },
                {
                    id: "medium",
                    size: "medium",
                    title: "Medium",
                    titleAr: "متوسط",
                    active: false
                },
                {
                    id: "large",
                    size: "large",
                    title: "Large",
                    titleAr: "كبير",
                    active: false
                }
            ],

        },
        {
            icon: "fas fa-tint",
            title: "Saturation",
            titleAr: "التشبع",
            id: "saturation-switcher",
            type: "checkbox",
            role: "switch",
            checked: false,
        },
    ]
}

const initialState = localStorage.getItem('accessability') ? JSON.parse(localStorage.getItem('accessability')) : accessabilityTools;

const accessabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_ACCESSABILITY:
            return {
                ...state,
                isActive: !state.isActive,
            }
        case CLOSE_ACCESSABILITY:
            return {
                ...state,
                isActive: false,
            }
        case ACCESSABILITY_CHANGE:
            return {
                ...state,
                tools: state.tools.map((tool) => {
                    if (tool.id == action.payload) {
                        return {
                            ...tool,
                            checked: !tool.checked,
                        }
                    } else {
                        return tool;
                    }
                })
            }
        case ACCESSABILITY_CHANGE_THEME:
            return {
                ...state,
                // Find The Color Switcher Tool, and Get the Color Theme from the options
                tools: state.tools.map((tool) => {
                    if (tool.id == 'color-switcher') {
                        return {
                            ...tool,
                            options: tool.options.map((option) => {
                                if (option.id == action.payload) {
                                    return {
                                        ...option,
                                        active: true,
                                    }
                                } else {
                                    return {
                                        ...option,
                                        active: false,
                                    }
                                }
                            })
                        }
                    } else {
                        return tool;
                    }
                })
            }
        default:
            return state;
    }
}

export default accessabilityReducer;