import React from 'react'
import Skeleton from 'react-loading-skeleton'

const MOEBreadCrumb = ({currentPage, loading, qrCode}) => {

    const [shareContent, setShareContent] = React.useState(false);

    const handleShareData = () => {
        if (qrCode) {
            setShareContent(!shareContent)
        }
    }

    const handelGoHomePage = () => {
        window.location.href = '/'
    }

    // Handle Print Page Button
    const handelPrintPage = () => {
        window.print()
    }

    // Handle Copy Page Link Button
    const handleCopyPageLink = () => {

        // copy page link from window.location.href
        const copyText = window.location.href;
        navigator.clipboard.writeText(copyText).then(() => {
            toast.success(isArabic ? 'تم نسخ الرابط' : 'Page link copied successfully', {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });

        }, (err) => {
            console.error('Async: Could not copy text: ', err);
        });
    }

    return (
        <div className='moe-breadcrumb my-3'>
            {
                loading ? <Skeleton height={48} /> :
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex flex-nowrap">
                                    <li className="breadcrumb-item">
                                        <a href="/" onClick={handelGoHomePage}>
                                            Home
                                        </a>
                                    </li>
                                    {
                                        currentPage &&
                                        <li className="breadcrumb-item d-flex active" aria-current="page">{currentPage}</li>
                                    }
                                </ol>
                            </nav>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type='button' className='btn btn-link px-2 mx-2' onClick={handelPrintPage}>
                                <i className="fas fa-print p-0" />
                                <span className='sr-only d-none d-md-inline'> {isArabic ? 'طباعة' : 'Print'}</span>
                            </button>
                            <button type='button' className='btn btn-link px-2 mx-2' onClick={handleShareData}>
                                <i className="fas fa-share-alt p-0" />
                                <span className='sr-only d-none d-md-inline'> {isArabic ? 'مشاركة' : 'Share'}</span>
                            </button>
                            {
                                shareContent &&
                                <div className='share-content'>
                                    <span className='text-muted'>
                                        {isArabic ? 'مسح لفتح الرابط على الجوال' : 'Scan to open on mobile'}
                                    </span>
                                    <div className='share-content__qr'>
                                        {qrCode && <img src={qrCode} alt='qr code' />}
                                    </div>

                                    <div className='copy-pagelink'>
                                        <button type='button' className='btn btn-link px-2 mx-2' onClick={handleCopyPageLink}>
                                            {
                                                isArabic ? 'نسخ الرابط' : 'Copy Page Link'
                                            }
                                        </button>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
            }

        </div>
    )
}

export default MOEBreadCrumb