// src/axiosConfig.js
import axios from 'axios';
import {siteURL} from '../constants/constants';

// Create an instance of Axios
const axiosConfig = axios.create({
    baseURL: siteURL, // Set base URL dynamically
    timeout: 30000, // Set timeout duration

});

// Request Interceptor (optional if you want to add additional headers or logging)
axiosConfig.interceptors.request.use(
    // You can add additional request configurations here (e.g., auth headers)
    (config) => config,
    (error) => Promise.reject(error)
);

// how to use axiosConfig if i want to add headers

// Response Interceptor (optional)
axiosConfig.interceptors.response.use(
    // You can process responses here if needed
    (response) => response,
    // Handle error responses
    (error) => Promise.reject(error)
);

export default axiosConfig;
