import React, { useState } from "react";
import MOELayout from "../../layouts/MOELayout";
import { mdiCheckCircle, mdiSend } from "@mdi/js";
import Icon from "@mdi/react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCheckbox,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import AlumniImage from "@assets/images/alumni-banner.jpg";
import AlumniMobileImage from "@assets/images/alumni-banner-mobile.jpg";

import "./styles.scss";

import MOEFormField from "@components/form/MOEFormField";
const USP_API = process.env.REACT_APP_USP_API

const GlobalAlumni = () => {
  const [isSubmited, setIsSubmited] = React.useState(false);
  const [submitMessage, setIsSubmitMessage] = React.useState("");
  const [submitStatus, setIsSubmitStatus] = React.useState(false);

  const [showSurvayError, setShowSurvayError] = React.useState(false);

  const [personalInfoFields, setPersonalInfoFields] = useState([
    {
      label: "Full name in English",
      labelAr: "الاسم الكامل باللغة الإنجليزية",
      type: "text",
      id: "fullNameEn",
      required: true,
      placeholder: isArabic ? "أدخل الاسم الكامل باللغة الإنجليزية" : "Enter Full Name in English",
      value: "",
      validationMsgEn:
        "Full Name (English) should contain only alphabetic characters.",
      validationMsgAr:
        "يجب أن يحتوي الاسم الكامل (الإنجليزية) على أحرف أبجدية فقط.",
      validationPattern: /^[A-Za-z\s]+$/,
      maxLength: 100,
      hidden: isArabic ? true : false,

    },
    {
      label: "Full name in Arabic",
      labelAr: "الاسم الكامل باللغة العربية",
      type: "text",
      id: "fullNameAr",
      required: true,
      placeholder: isArabic ? "أدخل الاسم الكامل باللغة العربية" : "Full name in Arabic",
      value: "",
      validationMsgEn:
        "Full Name (Arabic) should contain only Arabic characters.",
      validationMsgAr: "الاسم الكامل (عربي) يجب أن يحتوي على أحرف أبجدية فقط.",
      validationPattern: /^[\u0600-\u06FF\s]+$/,
      maxLength: 100,
      hidden: isArabic ? false : true,

    },
    {
      label: "Email address",
      labelAr: "عنوان البريد الإلكتروني",
      type: "email",
      id: "emailAddress",
      required: true,
      placeholder: isArabic ? "أدخل بريدك الإلكتروني" : "Enter your email",
      value: "",
      validationMsgEn: "Invalid email format.",
      validationMsgAr: "Invalid email format.",
      validationPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    },
    {
      label: "Mobile Number",
      labelAr: "رقم الهاتف الجوال",
      type: "text",
      id: "mobileNumber",
      required: true,
      placeholder: isArabic ? "ادخل رقم الجوال" : "Enter your number",
      value: "",
      validationMsgEn: "Mobile Number should contain only digits and +.",
      validationMsgAr:
        "يجب أن يحتوي رقم الهاتف المحمول على أرقام فقط بالإضافة إلى +.",
      validationPattern: /^(?:\+|00)[1-9]\d{1,14}$/,
      maxLength: 15
    },
    {
      label: "Date of birth",
      labelAr: "تاريخ الميلاد",
      type: "date",
      id: "dateOfBirth",
      required: true,
      placeholder: isArabic ? "اختر تاريخ الميلاد" : "Select a date",
      value: "",
    },
    {
      label: "Gender",
      labelAr: "الجنس",
      type: "select",
      id: "genderCode",
      options: [],
      required: true,
      placeholder: isArabic ? "اختر الجنس" : "Select gender",
      value: "",
    },
    {
      label: "University",
      labelAr: "الجامعة",
      type: "select",
      id: "universityID",
      options: [],
      required: true,

      placeholder: isArabic ? "اختر الجامعة" : "Select university",
      value: "",
    },
    {
      label: "Year of graduation",
      labelAr: "سنة التخرج",
      type: "select",
      id: "graduationYearCode",
      options: [],
      required: true,
      placeholder: isArabic ? "اختر سنة التخرج" : "Select year of graduation",
      value: "",
    },
    {
      label: "Degree/Area of study",
      labelAr: "الشهادة/مجال الدراسة",
      type: "select",
      id: "studyFieldID",
      options: [],
      required: true,

      placeholder: isArabic ? "اختر مجال الدراسة" : "Select area of study",
      value: "",
    },
    {
      label: "Enter Degree/Area of study",
      labelAr: "أدخل الشهادة/مجال الدراسة",
      type: "text",
      id: "otherStudyField",
      options: [],
      required: true,
      placeholder: isArabic ? "أدخل مجال الدراسة" : "Enter area of study",
      value: "",
      hidden: true,
    },
    {
      label: "Current location",
      labelAr: "مكان الإقامة الحالي",
      type: "select",
      id: "currentLocationID",
      options: [],
      placeholder: isArabic
        ? "اختر مكان الإقامة الحالي"
        : "Enter your current location",
      value: "",
      required: true,
    },
    {
      label: "Current role",
      labelAr: "المنصب الحالي",
      type: "text",
      id: "currentRole",
      required: true,
      maxLength: 300,
      placeholder: isArabic ? "اختر المنصب الحالي" : "Enter your current role",
      value: "",
    },
  ]);

  const [survay_options, setSurvayOptions] = useState([
    {
      id: "1",
      label: "Networking Opportunities",
      labelAr: "فرص التواصل",
      selected: false,
    },
    {
      id: "2",
      label: "Events & Webinars",
      labelAr: "الفعاليات والندوات",
      selected: false,
    },
    {
      id: "3",
      label: "Professional Development",
      labelAr: "التطوير المهني",
      selected: false,
    },
    {
      id: "4",
      label: "Alumni Stories & News",
      labelAr: "قصص الخريجين والأخبار",
      selected: false,
    },
    {
      id: "5",
      label: "Job Opportunities",
      labelAr: "فرص العمل",
      selected: false,
    },
    {
      id: "6",
      label: "Discounts & Offers",
      labelAr: "الخصومات والعروض",
      selected: false,
    },
    {
      id: "7",
      label: "Other",
      labelAr: "أخرى",
      maxCharLength: 300,
      selected: false,
      value: "",
    },
  ]);
  const handleSubmit = (e) => {
    setIsSubmited(true);

    let data = {};
    personalInfoFields.map((item) => {
      validate(item, item.value);

      data[item.id] = item.value;
    });
    let survay = [];
    survay_options.map((item) => {
      if (item.selected) {
        survay.push(item.id);
        data["surveyResponses"] = survay;
        if (item.id == "7") {
          data["otherResponse"] = item.value;
        }
      }
    });
    let index = survay_options.findIndex(
      (item) => item.id == 7 && item.selected
    );
    if (!data["surveyResponses"] || data["otherResponse"] == "")
      setShowSurvayError(true);
    else {
      setShowSurvayError(false);
    }

    if (Object.keys(errors).length !== 0) {
      return;
    }
    data["lang"] = isArabic ? 'ar' : 'en'
    var Settings = {
      async: true,
      url: `${USP_API}/CCC/alumni-profile`,
      method: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",

      headers: {
        Accept: "application/json;odata=verbose",
      },
    };
    $.ajax(Settings).done(function (response) {
      const results = response;
      if (response.applicationId) {
        setIsSubmitStatus(true);
        setIsSubmitMessage(response.applicationId)
        // const newFields =  personalInfoFields.map((item) => {
        //   item.value = "";
        //   return item
        // });
        // setPersonalInfoFields(newFields);

        // const newSurvay =  survay_options.map((item) => {
        //   item.selected = false;
        //   if (item.id == "7") item.value = "";
        //   return item
        // });
        // setSurvayOptions(newSurvay)
        setIsSubmited(false);
      }
      else {
        setIsSubmitStatus(false);
        setIsSubmitMessage(response?.validationErrors[0]?.description)

      }
    }).fail(function (jqXHR, textStatus) {
      setIsSubmitStatus(false);
      setIsSubmitMessage(jqXHR.responseJSON?.validationErrors[0]?.description)
    });
  };
  React.useEffect(() => {
    const lang = isArabic ? "ar" : "en";

    var countrySettings = {
      async: true,
      url:
        `${USP_API}/CCC/alumni-profile/lookups/${lang}`,
      method: "GET",
      headers: {
        Accept: "application/json;odata=verbose",
      },
    };

    $.ajax(countrySettings).done(function (response) {
      if (response) {
        console.log(response);
        const newFields = personalInfoFields.map((item) => {
          if (item.id === "genderCode") {
            let transformedGenders = response.genders.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));

            item.options = transformedGenders;
          } else if (item.id === "graduationYearCode") {
            let graduationYears = response.graduationYears.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            item.options = graduationYears;
          } else if (item.id === "currentLocationID") {
            let nationalities = response.nationalities.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            item.options = nationalities;
          } else if (item.id === "studyFieldID") {
            let studyFields = response.studyFields.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            item.options = studyFields;
          } else if (item.id === "surveyCodes") {
            let surveyCodes = response.surveyCodes.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            item.options = surveyCodes;
          } else if (item.id === "universityID") {
            let universities = response.universities.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            item.options = universities;
          }
          return item;
        });
        setPersonalInfoFields(newFields);
        let items = [];
        response.surveyCodes.list.map((item) => {
          if (item.id == "7") {
            items.push({
              id: item.id,
              label: item.name,
              labelAr: item.name,
              maxCharLength: 300,
              selected: false,
              value: "",
            });
          } else {
            items.push({
              id: item.id,
              label: item.name,
              labelAr: item.name,
              selected: false,
            });
          }
        });
        setSurvayOptions(items);
      }
    });
  }, []);

  const [dateOfBarth, setDateOfBarth] = useState("");
  const [errors, setErrors] = useState({});

  const handelSelectOption = (e) => {
    const { name, checked } = e.target;
    const newOptions = survay_options.map((item) => {
      if (item.id === name) {
        item.selected = checked;
      }
      return item;
    });
    setSurvayOptions(newOptions);
  };

  const handelOtherFieldChange = (e) => {
    const { name, value } = e.target;
    const newOptions = survay_options.map((item) => {
      // Check if the selected option is the other option
      // and the value length is less than the max length
      if (item.id === name && item.maxCharLength >= value.length) {
        item.value = value;
      } else {
        item.value = value.substring(0, item.maxCharLength);
      }
      setShowSurvayError(false);
      return item;
    });

    setSurvayOptions(newOptions);
  };

  const handelFormOnChange = (e) => {
    let other = "e9872b31-7863-ef11-a58d-0050560107f2";

    const newFields = personalInfoFields.map((item) => {
      if (item.id === e.id) {
        if (e.id == "studyFieldID" && e.value == other) {
          let field = personalInfoFields.find(
            (item) => item.id == "otherStudyField"
          );
          if (field) {
            field.hidden = false;
          }
        } else if (e.id == "studyFieldID" && e.value != other) {
          let field = personalInfoFields.find(
            (item) => item.id == "otherStudyField"
          );
          if (field) {
            field.hidden = true;
            delete errors[field.id];
          }
        }

        validate(item, item.value);
      }
      return item;
    });

    setPersonalInfoFields(newFields);
  };

  const renderTextContent = (arabicText, englishText) =>
    isArabic ? arabicText : englishText;
  const validate = (item, value) => {
    const pattern = item.validationPattern; // Regex for alphabetic characters and spaces only

    if (!value && item.required && !item.hidden) {
      errors[item.id] = isArabic
        ? "هذه الخانة لا يمكن ان تترك فارغة"
        : "This field is required";
    } else if (pattern && !pattern.test(value) && !item.hidden) {
      errors[item.id] = isArabic ? item.validationMsgAr : item.validationMsgEn;
    } else {
      errors[item.id] = "";
      delete errors[item.id];
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const renderSurveyOptions = () => {
    return survay_options.map((item, index) => (
      <MDBCol
        size={6}
        key={index}
        className={`${index === survay_options.length - 1 ? "mt-4" : ""}`}
      >
        <MDBCheckbox
          value={item.value || ""}
          id={`survay_option_${item.id}`}
          label={renderTextContent(item.labelAr, item.label)}
          name={item.id}
          onChange={handelSelectOption}
        />
      </MDBCol>
    ));
  };

  return (
    <MOELayout pageClassName="alumni-page global-alumni">
      <MDBRow className="content-container">
        {/* Banner Image */}
        <MDBCol className="text-center" size={12}>
          <div className="banner-image">
            {/* Desktop Image */}
            <img
              src={AlumniImage}
              className="w-100 d-none d-md-block"
              alt="Global Alumni"
            />
            {/*  Mobile Image */}
            <img
              src={AlumniMobileImage}
              className="w-100 d-block d-md-none"
              alt="Global Alumni"
            />
          </div>
        </MDBCol>

        {/* Page Content */}
        <MDBCol lg={8} className="content-column">
          <h2 className="page-title">
            {renderTextContent(
              "انضم إلى شبكة خريجي الإمارات الدولية وساهم معنا في رسم ملامح مستقبل أفضل للجميع.",
              "Join the UAE Global Alumni Network to shape a brighter future and create better world together."
            )}
          </h2>
          <p className="page-description">
            {renderTextContent(
              "تهدف رابطة خريجي الإمارات الدولية إلى ترسيخ مبدأ التعلم مدى الحياة لدى الخريجين، ودعم طموحاتهم، وخلق فرص للأعمال والابتكار من خلال الشراكة والتعاون المثمر. تسعى وزارة التعليم العالي والبحث العلمي إلى تعزيز روح التواصل والوحدة بين الخريجين ودولة الإمارات، مما يعزز مكانة الإمارات كدولة رائدة وشاملة تحتضن العالم. قم بالتسجيل مسبقاً الآن وانضم إلى مجتمع عالمي من المحترفين والمبتكرين.",
              "The UAE Global Alumni Association aims to promote lifelong learning among graduates, support their ambitions, and create business and innovation opportunities through partnerships and collaborations. The MOHESR (MoE) seeks to enhance the spirit of communication and unity between graduates and the UAE, thereby elevating the UAE's status as an inclusive and welcoming nation. Become a part of a vibrant global community by pre-registering now."
            )}
          </p>
          <h2 className="page-title">
            {renderTextContent(
              "أظهر اهتمامك. سجل مسبقًا الآن",
              "Show your interest. Pre-register now"
            )}
          </h2>

          {/* Form Card */}
          <MDBCard className="card-form">
            <MDBCardBody>
              <MDBCardTitle className="m-0">
                {renderTextContent("المعلومات الشخصية", "Personal information")}
              </MDBCardTitle>
              <form id="personal-form">
                {/* Form Fields */}
                {personalInfoFields.map(
                  (item, index) =>
                    !item.hidden && (
                      <MOEFormField
                        key={index}
                        data={item}
                        onChange={handelFormOnChange}
                        errors={errors}
                      />
                    )
                )}

                <hr />
                <h5 class="card-title m-0">{isArabic ? "استطلاع" : "Survey"}</h5>
                <div className="form-group">
                  <label htmlFor="">
                    {renderTextContent(
                      "ساعدنا في تعزيز خدماتنا عن طريق إخبارنا بالميزات التي تود رؤيتها في البوابة.",
                      "Help us enhance our services by letting us know what features you’d like to see in the portal."
                    )}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="d-block flex-grow-1">
                    <MDBRow >
                      {renderSurveyOptions()}

                      {survay_options.map(
                        (item) =>
                          item.id == "7" &&
                          item.selected && (
                            <MDBCol size={12}>
                              <textarea
                                className="form-control"
                                id="other"
                                rows="3"
                                placeholder={item.placeholder}
                                name={item.id}
                                maxLength={item.maxCharLength}
                                onChange={handelOtherFieldChange}
                              ></textarea>
                              <small className="form-text text-muted">
                                {item?.value?.length}/{item?.maxCharLength}
                              </small>
                            </MDBCol>
                          )
                      )}
                      {showSurvayError && (
                        <small style={{ color: "red" }}>
                          {isArabic
                            ? "هذه الخانة لا يمكن ان تترك فارغة"
                            : "This field is required"}
                        </small>
                      )}
                    </MDBRow>
                  </div>
                </div>

              </form>


              <div className="d-flex justify-content-end mt-5">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-primary d-flex gap-2 align-items-center justify-content-center"
                  style={{ maxWidth: 180, width: 180 }}
                >

                  {isArabic ? "إرسال التسجيل" : "Submit Registration"}

                </button>
              </div>
              {submitMessage && !submitStatus && (
                <div id="errorMessage" className="alert alert-danger">
                  {submitMessage}
                </div>
              )}
              {submitMessage && submitStatus && (
                <div id="successMessage" className="alert alert-success">
                  <p className='text-success'>{isArabic ?
                    `تم تقديم طلبك بنجاح.` : `Your request has been successfully submitted.`}</p>


                </div>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MOELayout>
  );
};

export default GlobalAlumni;
