import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_MODAL } from "@redux/actions";
import "./MOEModal.scss";
import MOESelect from "@components/form/MOESelect";
import moment from 'moment';

const MOEModal = () => {
  const { loading, result, showModal, showService } = useSelector(
    (state) => state.checkStatus
  );
  const [servicesList, setServicesList] = React.useState([]);
  const [filterdList, setFilterdList] = React.useState(null);
  const [selectedService, setSelectedService] = React.useState(null);

  const dispatch = useDispatch();

  const USP_API = process.env.REACT_APP_USP_API

  React.useEffect(() => {

    var countrySettings = {
      async: true,
      url: `${USP_API}/Services/get-eServices`,
      method: "GET",
      headers: {
        Accept: "application/json;odata=verbose",
      },
    };

    $.ajax(countrySettings).done(function (response) {
      const results = response;
      setServicesList(results);

    });
  }, []);

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
    setSelectedService(null)
    setFilterdList(null)
  };
  const handleChangeService = (service) => {
    setSelectedService(service)
    setFilterdList(
      result.filter((item) => {
        return item.productNumber == service.value;
      })
    );
  };

  return result ? (
    <MDBModal show={showModal} onHide={closeModal} className="moe-modal">
      <MDBModalDialog>
        <MDBModalContent>
          {/* <MDBBtn type='button' className='btn-close' color='none' onClick={closeModal}></MDBBtn> */}
          <button
            type="button"
            className="btn-close"
            data-mdb-dismiss="modal"
            onClick={closeModal}
            aria-label="Close"
          ></button>
          <MDBModalBody>
            <div className="result-content">
              {showService && (
                <div>
                  <h6 className="result-title">
                    {isArabic
                      ? "الرجاء اختيار الخدمة"
                      : "Plese Select the Service"}
                  </h6>
                  <MOESelect
                    className="w-100 mb-2"
                    placeholder={isArabic ? "اختر الخدمة" : "Select a service"}
                    noOptionsMessage={() =>
                      isArabic
                        ? "لا توجد نتيجة مطابقة"
                        : "There is no matched result"
                    }
                    value={selectedService}
                    onChange={(item) => handleChangeService(item)}
                    options={servicesList
                      .filter((service) =>
                        result.some(
                          (item) => service.productNumber === item.productNumber
                        )
                      )
                      .map((item) => ({
                        value: item.productNumber,
                        label: isArabic
                          ? item.serviceNameAR
                          : item.serviceNameEN,
                      }))}
                  />
                </div>
              )}
              {!filterdList && result.length == 1 && (
                <MDBRow>
                  <MDBCol md="12">
                    <b className="result-title">
                      {isArabic ? "الخدمة" : "Service"}
                    </b>
                    <p className="result-text">
                      {isArabic
                        ? result[0].serviceNameAR
                        : result[0].serviceNameEN}
                    </p>
                  </MDBCol>
                  <MDBCol md="6">
                    <b className="result-title">
                      {isArabic ? "تاريخ التقديم" : "Application date"}
                    </b>
                    <p className="result-text">{moment(result[0].submitDate).locale(isArabic ? 'ar_SA' : '').format('DD MMM YYYY')}</p>
                  </MDBCol>
                  <MDBCol md="6">
                    <b className="result-title">
                      {isArabic ? "حالة الطلب" : "Application status"}
                    </b>
                    <p
                      className={`result-text text-${(
                        result[0].statusCodeEN === "Approved" ||
                        result[0].statusCodeEN === "Answered" ||
                        result[0].statusCodeEN === "Complete") ? "success"

                        : (
                          result[0].statusCodeEN === "Canceled" ||
                          result[0].statusCodeEN === "Returned" ||
                          result[0].statusCodeEN === "Rejected") ? "danger"
                          : result[0].statusCodeEN === "Pending Payment" ? 'warning'

                            : 'info'
                        }`}
                    >
                      {isArabic
                        ? result[0].statusCodeAR
                        : result[0].statusCodeEN}
                    </p>
                  </MDBCol>
                </MDBRow>
              )}
              {filterdList && (
                <MDBRow>
                  <MDBCol md="12">
                    <b className="result-title">
                      {isArabic ? "الخدمة" : "Service"}
                    </b>
                    <p className="result-text">
                      {isArabic
                        ? filterdList[0].serviceNameAR
                        : filterdList[0].serviceNameEN}
                    </p>
                  </MDBCol>
                  <MDBCol md="6">
                    <b className="result-title">
                      {isArabic ? "تاريخ التقديم" : "Application date"}
                    </b>
                    <p className="result-text">{moment(filterdList[0].submitDate).locale(isArabic ? 'ar_SA' : '').format('DD MMM YYYY')}</p>
                  </MDBCol>
                  <MDBCol md="6">
                    <b className="result-title">
                      {isArabic ? "حالة الطلب" : "Application status"}
                    </b>
                    <p
                      className={`result-text text-${(
                        filterdList[0].statusCodeEN === "Approved" ||
                        filterdList[0].statusCodeEN === "Answered" ||
                        filterdList[0].statusCodeEN === "Complete") ? "success"

                        : (
                          filterdList[0].statusCodeEN === "Canceled" ||
                          filterdList[0].statusCodeEN === "Returned" ||
                          filterdList[0].statusCodeEN === "Rejected") ? "danger"
                          : filterdList[0].statusCodeEN === "Pending Payment" ? 'warning'

                            : 'info'
                        }`}
                    >
                      {isArabic
                        ? filterdList[0].statusCodeAR
                        : filterdList[0].statusCodeEN}
                    </p>
                  </MDBCol>
                </MDBRow>
              )}
            </div>

            <div className="text-center mt-4">
              <MDBBtn
                type="button"
                className="text-white btn-primary btn-block"
                href={
                  isArabic
                    ? "https://usp.moe.gov.ae/ar/myapplications/all"
                    : "https://usp.moe.gov.ae/en/myapplications/all"
                }
              >
                {isArabic ? "الرجوع إلى تطبيقاتي" : "My Applications"}
              </MDBBtn>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  ) : null;
};

export default MOEModal;
