import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalHeader,
  MDBModalDialog,
  MDBRow,
  MDBModalTitle
} from 'mdb-react-ui-kit';
import AlumniImage from '../../assets/images/alumni-banner.jpg'
import AlumniMobileImage from '../../assets/images/alumni-banner-mobile.jpg'

import React from 'react';
import MOELayout from '../../layouts/MOELayout';
import PWC from '../../assets/images/pwc.png';
import './styles.scss';
import { Fade } from 'react-reveal';

const AlumniPartners = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedPartner, setSelectedPartner] = React.useState("");

  const partnersList = [
    // {
    //   companyName: isArabic ? "ويبر شاندويك" : 'Weber Shandwick',
    //   companyLogo: WeberShandwick,
    //   offers: `
    //     <h6>Trainings on</h6> 
    //     <ul>
    //       <li>Social media 101 course</li>
    //       <li>PR 101 course</li>
    //       <li>Media Relations foundations course</li>
    //       <li>Content writing training</li>
    //       <li>Any other communication training the graduates might need.</li>
    //     </ul>
    //   `,
    //   offersAr: `
    //     <h6>التدريب على</h6>
    //     <ul>
    //       <li>دورة وسائل التواصل 101</li>
    //       <li>دورة علاقات عامة 101 </li>
    //       <li>دورة أساسيات العلاقات الإعلامية</li>
    //       <li>تدريب على كتابة المحتوى</li>
    //       <li>أي تدريب آخر في مجال الاتصالات قد يحتاجه الخريجون.</li>
    //     </ul>
    //   `,
    // },
    // {
    //   companyName: isArabic ? "تعيين" : 'Taaeen',
    //   companyLogo: Taaeen,
    //   offers: `
    //     <ul>
    //       <li>15 - 25% discount on all qualifications provided by Taaeen.</li>
    //       <li>30% discount on all soft skills and management training programs.</li>
    //     </ul>
    //   `,
    //   offersAr: `
    //     <ul>
    //       <li>خصم 15-25% على كافة المؤهلات المقدمة من شركة تعيين.</li>
    //       <li>خصم 30% على كافة برامج التدريب على المهارات الشخصية والإدارية.</li>
    //     </ul>
    //   `,
    // },
    // {
    //   companyName: isArabic ? "معهد المنهل للثقافة والحاسب الآلي" : 'Al Manhal Institute for Culture and Computer',
    //   companyLogo: AlManhal,
    //   offers: `
    //     <ul>
    //       <li>30% discount on training courses.</li>
    //       <li>20% discount on the annual subscription to the e-learning platform.</li>
    //     </ul>
    //   `,
    //   offersAr: `
    //     <ul>
    //       <li>خصم 30% على دورات التدريب.</li>
    //       <li>خصم 20% على الاشتراك السنوي في منصة التعليم الإلكتروني.</li>
    //     </ul>
    //   `,
    // },
    {
      companyName: 'PWC',
      companyLogo: PWC,
      offers: `
        <p class="modal-description py-3 border-bottom">
          We’re excited collaborate with PWC and showcase their extensive range of professional courses. Their offerings cover various domains including HR, Finance, Accounting, Tax, and Procurement. Here are some of the qualifications that you can find:
        </p>
        <div class="d-flex flex-column gap-3">
        
          <h5>HR</h5>
          <ul>
            <li>
              <a href="#!">Chartered Institute of Personnel and Development (CIPD)</a>
            </li>
          </ul>
          
          <h5>Finance and Accounting:</h5>
          <ul>
          <li>
          <a href="#!">Chartered Financial Analyst (CFA)</a>
          </li>
          <li>
          <a href="#!">Certified Management Accountant (CMA)</a>
          </li>
          <li>
          <a href="#!">ACCA</a>
          </li>
          <li>
          <a href="#!">Certified Public Accountant (CPA)</a>
          </li>
          </ul>
          
          <h5>Tax</h5>
          <ul>
            <li>
              <a href="#!">VAT Compliance Diploma</a>
            </li>
          </ul>

          <h5>Procurement</h5>
          <ul>
            <li>
              <a href="#!">Chartered Institute of Personnel and Development (CIPS)</a>
            </li>
          </ul>
          </div>
        <p>
          For more detailed information on each course and to access the full capablilities, please download the brochure by clicking the button.
        </p>
        <a href='/En/ImportantLinks/GlobalAlumni/Documents/PwC%27s%20Academy%20capabilities%20brochure%20UAE.pdf' target='_blank'>
        <button class="btn btn-primary" type="button"> 
        
        Download brochure 
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7879_2240)">
        <mask id="mask0_7879_2240" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="square"/>
        <path d="M7 10L12 15L17 10" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="square"/>
        <path d="M12 14V3" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask0_7879_2240)">
        <rect width="24" height="24" fill="white"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_7879_2240">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
</button>
        </a>
      `,
      offersAr: `
       <p class="py-3 border-bottom">
          نحن متحمسون للتعاون مع PWC واستعراض مجموعة دوراتهم المهنية الشاملة. تشمل عروضهم مجالات متنوعة مثل الموارد البشرية والمالية والمحاسبة والضرائب والمشتريات. فيما يلي بعض المؤهلات التي يمكنك العثور عليها:
</p>
        <div class="d-flex flex-column gap-3">
        
          <h5>الموارد البشرية</h5>
          <ul>
            <li>
              <a href="#!">المعهد المعتمد لشؤون الأفراد والتنمية (CIPD)</a>
            </li>
          </ul>
          
          <h5>المالية والمحاسبة</h5>
          <ul>
          <li>
          <a href="#!">المحلل المالي المعتمد (CFA)</a>
          </li>
          <li>
          <a href="#!">المحاسب الإداري المعتمد (CMA)</a>
          </li>
          <li>
          <a href="#!">ACCA</a>
          </li>
          <li>
          <a href="#!">المحاسب القانوني المعتمد (CPA)</a>
          </li>
          </ul>
          
          <h5>الضرائب</h5>
          <ul>
            <li>
              <a href="#!">دبلوم الامتثال لضريبة القيمة المضافة</a>
            </li>
          </ul>

          <h5>المشتريات</h5>
          <ul>
            <li>
              <a href="#!">المعهد المعتمد لشؤون الأفراد والتنمية (CIPS)</a>
            </li>
          </ul>
          </div>
        <p>
          للحصول على مزيد من المعلومات التفصيلية حول كل دورة والوصول إلى جميع الإمكانيات، يرجى تحميل الكتيب بالنقر على الزر.
        </p>
         <a href='/En/ImportantLinks/GlobalAlumni/Documents/PwC%27s%20Academy%20capabilities%20brochure%20UAE.pdf' target='_blank'>
        <button class="btn btn-primary" type="button"> 
        تحميل الكتيب 
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_7879_2240)">
          <mask id="mask0_7879_2240" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="square"/>
          <path d="M7 10L12 15L17 10" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="square"/>
          <path d="M12 14V3" stroke="#1B1D21" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          </mask>
          <g mask="url(#mask0_7879_2240)">
          <rect width="24" height="24" fill="white"/>
          </g>
          </g>
          <defs>
          <clipPath id="clip0_7879_2240">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          </button>
        </a>
      `,
    },
  ];

  const handelOpenPartnerDetails = (e, partner) => {
    e.preventDefault();
    // if (!partner || partner.offers === '' || partner.offersAr === '' || partner.offers === undefined || partner.offersAr === undefined) return;
    setSelectedPartner(partner);
    setShowModal(true);
  }

  return (
    <MOELayout pageClassName="alumni-page alumni-partners">
      <MDBRow className='content-container'>
        {/* Banner Image */}
        <MDBCol className="text-center" size={12}>
          <div className="banner-image">
            {/* Desktop Image */}
            <img
              src={AlumniImage}
              className='w-100 d-none d-md-block'
              alt="Global Alumni"
            />
            {/*  Mobile Image */}
            <img src={AlumniMobileImage}
              className='w-100 d-block d-md-none'
              alt="Global Alumni"
            />
          </div>
        </MDBCol>
        <MDBCol size={12}>
          <h3 className='page-titel'>{isArabic ? "الخريجين - الشركاء" : "Alumni - Partners"}</h3>
          <div className='page-description d-flex flex-column gap-2'>
            {isArabic ?
              <p> شبكة خريجي الإمارات الدولية تعمل بشكل وثيق مع شركاء رئيسيين، تشمل مؤسسات التعليم العالي في جميع أنحاء الإمارات، والشركات الخاصة والحكومية،  ابحث عن أصحاب العمل والشركاء المتعاونين من القائمة أدناه. تتغير قائمة الشركاء باستمرار، لذا يُنصح بمراجعتها بانتظام.</p>
              :
              (<>
                <p>
                  The UAE Global Alumni Network works closely with key partners – higher education institutions across the UAE, private companies, non-profit organizations, government agencies and embassies- to fully maximize the potential of UAE alumni and the network.
                </p>
                <p>
                  Search for potential employers, and collaborative partners from the list below. The partner list is changing frequently so be sure to check back often.
                </p>
              </>)
            }
          </div>
        </MDBCol>

        {/* ======================= */}
        {/* ==== Partners List ====  */}
        {/* ======================= */}
        <Fade left cascade duration={500}>
          <div className='row'>
            {partnersList.map((partner, index) => (
              <MDBCol key={index} size="6" lg="3" className="mb-4">
                <MDBCard className="partner-card">
                  <MDBCardBody onClick={(e) => handelOpenPartnerDetails(e, partner)}>
                    <img className='partner-logo' src={partner.companyLogo} alt={partner.companyName} />
                    <h4 className="card-title partner-title">{partner.companyName}</h4>
                    <span href='#!' className='show-partner-details'>{isArabic ? "عرض" : "View"}</span>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </div>
        </Fade>
        {/* ==================== */}
        {/* ==== Show Modal ==== */}
        {/* ==================== */}
        {
          showModal &&
          <MDBModal id='alumni-partners-modal' className='moe-modal' show={showModal} onHide={() => setShowModal(false)}       >
            <MDBModalDialog size='xl'>
              <MDBModalContent>
                <MDBModalHeader>
                  <button className="btn-close" color="none" onClick={() => setShowModal(false)}></button>
                  <MDBModalTitle className='m-0'>{selectedPartner && selectedPartner?.companyName}</MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className='d-flex flex-column align-items-start gap-3' dangerouslySetInnerHTML={{ __html: (isArabic ? selectedPartner?.offersAr : selectedPartner?.offers) || (isArabic ? 'لا توجد عروض متاحة' : 'No offers available') }} />
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>}
      </MDBRow>
    </MOELayout>
  );
};

export default AlumniPartners;
