import React, {forwardRef, useEffect} from 'react'
import DatePicker, {registerLocale} from "react-datepicker";
import Icon from '@mdi/react';
import {mdiCalendarBlankOutline} from '@mdi/js';
import {ar} from 'date-fns/locale';
import moment from 'moment';
import './MOEDatePicker.scss';

const MOEDatePicker = ({ref, ...rest}) => {
    useEffect(() => {
        registerLocale('ar', ar)
    })

    const CustomInput = forwardRef((props, ref) => (
        <div className='d-flex align-items-center justify-content-between'>
            <input
                ref={ref}
                type="text"
                placeholder={isArabic ? 'سنة التخرج' : 'Graduation year'}
                className="react-datepicker-ignore-onclickoutside"
                style={{flex: 1}}
                value={props.value ? moment(props.value).format('YYYY') : ''}
                readOnly
                {...props}
                inputMode='none'
            />
            <Icon
                onClick={props.onClick}
                className='react-datepicker__calendar-icon' path={mdiCalendarBlankOutline} size={1} />
        </div>))

    return (
        <div className="aegov-form-control">
            <div className="moe-date-picker form-control-input">
                <DatePicker
                    showIcon={false}
                    dropdownMode="select"
                    customInput={<CustomInput ref={ref} />}
                    locale={isArabic ? 'ar' : 'en'}
                    className='form-control'
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth // show the next and previous month button
                    showMonthDropdown // show the month in dropdown
                    showYearDropdown // show the year in dropdown   
                    maxDate={new Date()}
                    {...rest}
                    inputProps={{
                        inputMode: 'none'
                    }}

                />
            </div>
        </div>
    )
}

export default MOEDatePicker