import { SET_PRE_ASSESSMENT_ERROR, SET_PRE_ASSESSMENT_FORM_SUBMITTED, SET_PRE_ASSESSMENT_FORM_SUBMITTING, SET_PRE_ASSESSMENT_FORM_SUBMIT_ERROR, SET_PRE_ASSESSMENT_FORM_SUBMIT_SUCCESS, SET_PRE_ASSESSMENT_FORM_VALID, SET_PRE_ASSESSMENT_VALUE, SET_PRE_ASSESSMENTـLOOKUPS, SET_PRE_ASSESSMENTـOPTIONS } from "../actions";

const initialState = {
    preAssessmentForm: [
        {
            name: "country",
            label: "Select a country",
            labelAr: "اختر بلد الدراسة",
            options: [],
            isRequired: true,
            value: "",
            isFullWidth: true,
            isDisabled: false,
            error: null
        }, {
            name: "institute",
            label: "Select an institute",
            labelAr: "اختر الجامعة",
            options: [],
            isRequired: true,
            value: "",
            isFullWidth: true,
            // disabled: state => state.preAssessmentForm.country.value === "",
            error: null
        },
        {
            name: "studyLevel",
            label: "Select a study level",
            labelAr: "اختر مستوى الدراسة",
            options: [],
            isRequired: true,
            value: "",
            isFullWidth: true,
            // isDisabled: state => state.preAssessmentForm.institute.value === "",
            error: null
        },
        {
            name: "studyAreaCode",
            label: "Select area of study",
            labelAr: "اختر مجال الدراسة",
            options: [],
            isRequired: true,
            value: "",
            isFullWidth: true,
            // isDisabled: state => state.preAssessmentForm.studyLevel.value === "",
            error: null
        },
        {
            name: "studyStartDate",
            label: "Study Start year",
            labelAr: "سنة بداية الدراسة",
            // get the last 60 years from the current year
            options: (function () {
                const currentYear = new Date().getFullYear();
                return Array.from(new Array(60), (val, index) => {
                    return {
                        id: currentYear - index,
                        nameAR: currentYear - index,
                        nameEN: currentYear - index,
                    }
                });
            })(),
            isRequired: true,
            value: "",
            isFullWidth: false,
            // isDisabled: state => state.preAssessmentForm.studyAreaCode.value === "",
            error: null
        },
        {
            name: "studyEndDate",
            label: "Study end year",
            labelAr: "سنة التخرج",
            // get the last 60 years , but don't include the previous year of the study start year
            //    Function to get the last 60 years from the current year, but don't include the previous year of the study start year
            options: (function () {
                const studyStartYear = new Date().getFullYear();
                return Array.from(new Array(60), (val, index) => {
                    return {
                        id: studyStartYear - index - 1,
                        nameAR: studyStartYear - index - 1,
                        nameEN: studyStartYear - index - 1,
                    }
                });
            })(),
            isRequired: true,
            // check if the study start year is has a value and the study end year is not less than the study start year
            // isDisabled: state => state.preAssessmentForm.studyStartDate.value === "" || state.preAssessmentForm.studyStartDate.value >= state.preAssessmentForm.studyEndDate.value,
            value: "",
            isFullWidth: false,
            error: null
        }
    ],
    lookups: {},
    isFormValid: false,
    isFormSubmitted: false,
    isFormSubmitting: false,
    isFormSubmitSuccess: false,
    isFormSubmitError: false,
}


export const preAssessmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRE_ASSESSMENTـOPTIONS:
            const { name, options } = action.payload;

            const updatedFormItem = state.preAssessmentForm.map((item) => {
                if (item.name === name) {
                    return { ...item, options: options };
                }
                return item;
            });

            return { ...state, preAssessmentForm: updatedFormItem };

        case SET_PRE_ASSESSMENT_VALUE:

            const { nameValue, value } = action.payload;

            const updatedFormItemValue = state.preAssessmentForm.map((item) => {
                if (item.name === nameValue) {
                    return { ...item, value: value };
                }
                return item;
            });
            
            return { ...state, preAssessmentForm: updatedFormItemValue };

        case SET_PRE_ASSESSMENT_ERROR:
            return state.isFormValid.map((item) => {
                if (item.name === action.payload.name) {
                    return { ...item, error: action.payload.error };
                }
                return item;
            });
        case SET_PRE_ASSESSMENTـLOOKUPS:
            return { ...state, lookups: action.payload };

        case SET_PRE_ASSESSMENT_FORM_VALID:
            return { ...state, isFormValid: action.payload };

        case SET_PRE_ASSESSMENT_FORM_SUBMITTED:
            return { ...state, isFormSubmitted: action.payload };

        case SET_PRE_ASSESSMENT_FORM_SUBMITTING:
            return { ...state, isFormSubmitting: action.payload };

        case SET_PRE_ASSESSMENT_FORM_SUBMIT_SUCCESS:
            return { ...state, isFormSubmitSuccess: action.payload };

        case SET_PRE_ASSESSMENT_FORM_SUBMIT_ERROR:
            return { ...state, isFormSubmitError: action.payload };

        default:
            return state;
    }
}
