/* eslint-disable import/no-unused-modules */
import axiosConfig from "@api";

const servicesCards = `/${isArabic ? 'ar' : 'en'}/_api/lists/getbytitle('Service%20Cards')/items?$orderby=Order0%20asc`;
const servicesCategories = `/${isArabic ? 'ar' : 'en'}/_api/lists/getbytitle('ServicCatalog')/items`;

const servicesByPageName = (pageName) => `/${isArabic ? 'ar' : 'en'}/_api/web/lists/getbytitle('Service%20Cards')/items?$filter=ServicePageName eq '${pageName}'`;

export const getServicesCards = () => axiosConfig.get(servicesCards);
export const getServicesByPageName = (pageName) => axiosConfig.get(servicesByPageName(pageName));
export const getServicesCategories = () => axiosConfig.get(servicesCategories);
