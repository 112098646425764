
// Desc: This file contains all the helper functions that are used in the project
import MOELOADER from '@assets/images/MoE-preloader.svg'

export function AppLoader() {
    // create loader and remove it after loading
    document.createElement("div").setAttribute("id", "moe-loader");
    document.body.appendChild(document.createElement("div")).setAttribute("id", "moe-loader");
    const loader = document.getElementById("moe-loader");

    ReactDOM.createRoot(loader).render(
        <div className="loader-img">
            {/* <div dangerouslySetInnerHTML={MOELOADER} /> */}
            <img src={MOELOADER} width={75} height={75} />
        </div>
    );
}

// 1- ArrayWithoutDuplicates : This function takes an [array] and a [parameter key] 
// and returns an array of objects with unique values of the parameter key
export const ArrayWithoutDuplicates = (array, parameterKey) => {
    const uniqueList = [...new Set(array.map((item) => item[parameterKey]))]
        .map((item) => item == null ? null : array.find((item2) => item2[parameterKey] == item)).map((item) => item == null ? null : {
            Title: item.ServiceKeywords || '',
            ID: item.ServicCatalogId,
            active: false,
        });
    return [
        {
            Title: isArabic ? 'كل الخدمات' : 'All Services',
            ID: 'all',
            active: true,
        },
        ...uniqueList
    ];
}

export function getLang() {
    let language =
        window._spPageContextInfo
            ? (window._spPageContextInfo.currentLanguage === 1025 ? 'ar' : 'en')
            : (localStorage.getItem('pmo_service_observatory') ? JSON.parse(localStorage.getItem('pmo_service_observatory')).language : 'en');

    if (language === 'ar') {
        // RTL Layout
        isArabic = true;
        // alert(isArabic ? 'ar' : 'en');
        $('body').addClass('rtl');
        return 'ar';
    }
    // LTR Layout
    isArabic = false;
    $('body').removeClass('rtl');
    // alert(isArabic ? 'ar' : 'en');
    return 'en';

}

export function checkIsLive() {
    let url = window.location.href;
    if (url.indexOf('localhost') > -1) {
        isLive = false;
    } else {
        isLive = true;
    }
    window.isLive = isLive;
}

export function removeSpecialText(str) {
    return str.toLowerCase().replace('https://www.moe.gov.ae', '');
}

export function iniatAccesibility() {
    //================================================================
    // 1- check if local storage if has values or not
    //================================================================
    // check if local storage has color
    const params = new URLSearchParams(window.location.search);
    if (params.get("themeColor")) {
        $('body').attr('data-color', `${params.get("themeColor")}-theme`);
        $(`.color-switcher .color-switcher-item[data-color="${params.get("themeColor")}"]`).addClass('active').siblings().removeClass('active');
    } else if (localStorage.getItem('color')) {
        $('body').attr('data-color', localStorage.getItem('color'));
        $(`.color-switcher .color-switcher-item[data-color="${localStorage.getItem('color').replace('-theme', '')}"]`).addClass('active').siblings().removeClass('active');
    } else {
        $('body').attr('data-color', 'gold-theme');
        $('.color-switcher .color-switcher-item[data-color="gold"]').addClass('active').siblings().removeClass('active');
    }

    // check if local storage has dark mode
    if (params.get("brightness")) {
        const isDarkMode = params.get("brightness") == 'dark';
        if (isDarkMode) {
            document.body.classList.add("dark-mode");
            document.querySelector('.dark-mode-switcher input').checked = true;
        } else {
            document.body.classList.remove("dark-mode");
            document.querySelector('.dark-mode-switcher input').checked = false;
        }
    } else if (localStorage.getItem('dark-mode') || localStorage.getItem('darkMode')) {
        if (localStorage.getItem('dark-mode') == 'true' || localStorage.getItem('darkMode') == 'true') {
            $('body').addClass('dark-mode dark');
            $('.dark-mode-switcher input').prop('checked', true);
        }
    } else {
        $('body').removeClass('dark-mode');
        $('.dark-mode-switcher input').prop('checked', false);
    }

    // check if local storage has contrast mode
    if (localStorage.getItem('contrast-mode') || localStorage.getItem('contrastMode')) {
        if (localStorage.getItem('contrast-mode') == 'true' || localStorage.getItem('contrastMode') == 'true') {
            $('body').addClass('contrast-mode');
            $('#contrast-switcher').prop('checked', true);
        } else {
            $('body').removeClass('contrast-mode');
            $('#contrast-switcher').prop('checked', false);
        }
    }

    // check if local storage has font size
    if (localStorage.getItem('font-size')) {
        $('body').attr('data-font', localStorage.getItem('font-size'));
        $(`.font-size-switcher-item[data-size="${localStorage.getItem('font-size')}"]`).addClass('active').siblings().removeClass('active');
    } else {
        $('body').attr('data-font', '1');
        $('.font-size-switcher-item[data-size="1"]').addClass('active').siblings().removeClass('active');
    }

    // check if local storage has cursor size
    if (localStorage.getItem('cursor-size')) {
        $('body').attr('data-cursor', localStorage.getItem('cursor-size'));
        $(`.cursor-size-switcher-item[data-size="${localStorage.getItem('cursor-size')}"]`).addClass('active').siblings().removeClass('active');
    } else {
        $('body').attr('data-cursor', '1');
        $('.cursor-size-switcher-item[data-size="1"]').addClass('active').siblings().removeClass('active');
    }

    // check if local storage has saturation
    if (localStorage.getItem('saturation-mode')) {
        if (localStorage.getItem('saturation-mode') == 'true') {
            $('body').addClass('saturation-mode');
            $('#saturation-switcher').prop('checked', true);
        } else {
            $('body').removeClass('saturation-mode');
            $('#saturation-switcher').prop('checked', false);
        }
    }

    //================================================================
    // Color Switcher
    //================================================================
    $('.color-switcher .color-switcher-item').on('click', function () {
        let color = $(this).attr('data-color');
        $(this).addClass('active').siblings().removeClass('active');
        // get pervious color
        $('body').attr('data-color', `${color}-theme`);

        // save color in local storage
        localStorage.setItem('color', `${color}-theme`);
        if (typeof cxone === 'function') {
            cxone('chat', "setCustomCss", liveChatStyle(getColorTheme(), checkDarkMode()));
        }

    });

    //================================================================
    // Input Switch to Dark mode || Add class dark-mode to body
    //================================================================
    $('.dark-mode-switcher input').on('change', function () {
        if ($(this).is(':checked')) {
            $('body').addClass('dark-mode dark');
        } else {
            $('body').removeClass('dark-mode dark');
        }
        // save dark mode in local storage
        localStorage.setItem('dark-mode', $(this).is(':checked'));
        if (typeof cxone === 'function') {
            cxone('chat', "setCustomCss", liveChatStyle(getColorTheme(), checkDarkMode()));
        }
    });
    //===========================================================
    // Input contrast-switcher || Add class contrast-mode to body
    //===========================================================
    $('#contrast-switcher').on('change', function () {
        if ($(this).is(':checked')) {
            $('body').addClass('contrast-mode');
        } else {
            $('body').removeClass('contrast-mode');
        }
        // save contrast mode in local storage
        localStorage.setItem('contrast-mode', $(this).is(':checked'));
        if (typeof cxone === 'function') {

            cxone('chat', "setCustomCss", liveChatStyle(getColorTheme(), checkDarkMode()));
        }
    });

    //===========================================================
    // Input font-switcher || Add class font-mode to body
    //===========================================================
    $('.font-size-switcher-item').on('click', function () {
        let size = $(this).data('size');
        $('body').attr('data-font', size);
        $(this).addClass('active').siblings().removeClass('active');

        // save font size in local storage
        localStorage.setItem('font-size', size);
    });

    //=======================
    // Cursor Size Switcher
    //=======================
    $('.cursor-size-switcher-item').on('click', function () {
        let size = $(this).data('size');
        $('body').attr('data-cursor', size);
        $(this).addClass('active').siblings().removeClass('active');

        // save cursor size in local storage
        localStorage.setItem('cursor-size', size);
    });

    //=======================
    // Highlight Switcher
    //=======================
    $('#highlight-switcher').on('change', function () {
        if ($(this).is(':checked')) {
            $('body').addClass('highlight-mode');
        } else {
            $('body').removeClass('highlight-mode');
        }
        // save highlight mode in local storage
        localStorage.setItem('highlight-mode', $(this).is(':checked'));
    });

    //================================================================
    // Saturation input || Add class saturation-mode to body
    //================================================================
    $('#saturation-switcher').on('change', function () {
        if ($(this).is(':checked')) {
            $('body').addClass('saturation-mode');
        } else {
            $('body').removeClass('saturation-mode');
        }
        // save saturation mode in local storage
        localStorage.setItem('saturation-mode', $(this).is(':checked'));
    });

    //=======================
    // Accessibility Reset
    //=======================
    $('#reset-accessibility').on('click', () => {
        // remove all classes from body
        $('body').removeClass('dark-mode dark contrast-mode font-size');
        // remove all attributes from body
        $('body').removeAttr('data-color');
        $('body').removeAttr('data-font');
        // remove all local storage
        localStorage.clear();
        // remove all active classes from color switcher || and set default color to gold and add active class to first item
        $('.color-switcher .color-switcher-item[data-color="gold"]').addClass('active').siblings().removeClass('active');
        // remove all active classes from font size switcher  || and set default font size to 1 and add active class to first item
        $('.font-size-switcher-item[data-size="1"]').addClass('active').siblings().removeClass('active');
        // remove all active classes from cursor size switcher  || and set default cursor size to 1 and add active class to first item
        $('.cursor-size-switcher-item[data-size="1"]').addClass('active').siblings().removeClass('active');
        $('body').removeAttr('data-cursor');
        // remove all active classes from font size switcher
        $('.dark-mode-switcher input').prop('checked', false);
        // remove all active classes from font size switcher
        $('#contrast-switcher').prop('checked', false);
        // remove saturation mode class from body || then set input to false
        $('body').removeClass('saturation-mode');
        $('#saturation-switcher').prop('checked', false);
        // remove highlight mode class from body || then set input to false
        $('body').removeClass('highlight-mode');
        $('#highlight-switcher').prop('checked', false);
        // resetGoogleTranslateCookie()
    });

}

//================================================================
//
//================================================================
export const setTooltipContent = () => {

    let tooltipContentAR = "يوجهك هذا الرابط إلى موقع خارجي قد تكون له سياسات مختلفة للمحتوى والخصوصية عن موقع وزارة التعليم العالي والبحث العلمي"
    let tooltipContentEN = "This link will direct you to an external website that may have different content and privacy policies than MoE website"

    const excludedDomains = ['mohesr.gov.ae', 'advanced.aspx', 'moe.gov.ae', 'moe.exceedgulf.com', 'staging.moe.gov.ae', '/', '', '../', '#', 'mailto:', 'javascript:', 'tel:',];

    const excludedClasses = ['fancybox'];

    const internalLinkSelector = `a[href]:not([href*="${excludedDomains.join('"]):not([href*="')}"]):not([class*="${excludedClasses.join('"]):not([class*="')}"])`;

    // Select elements using jQuery
    const elementsToWrap = $(internalLinkSelector);

    // Wrap selected elements with MDBTooltip component
    elementsToWrap.each(function () {
        const tooltipContent = isArabic ? tooltipContentEN : tooltipContentAR;
        const tooltip = (
            <MDBTooltip placement="top" tooltipContent={tooltipContent}>
                {$(this).html()}
            </MDBTooltip>
        );

        ReactDOM.render(tooltip, this);
    })
}

//================================================================
// Get URL Parameter by key 
//================================================================
export const handelingURLParams = (key) => {
    const urlObject = new URL(window.location.href);
    const param = urlObject.searchParams.get(key);
    return param;
};

//================================================================
// Fix Header Effect
//================================================================
export const fixedHeader = () => {
    $(document).ready(() => {
        const header = document.querySelector('.moe-header') || null;
        $(isLive ? "#s4-workspace" : window).on('scroll', () => {
            if ($(isLive ? "#s4-workspace" : window).scrollTop() >= 100) {
                header.classList.add('fixed-header');
            } else {
                header.classList.remove('fixed-header');
            }
        });
    });
}

//================================================================
// Fix Header Effect
//================================================================
export const getSchoolRating = (value) => {
    switch (value) {
        case "1":
            return {
                color: "#8bc34a",
                text: "Excellent",
                textAr: "ممتاز",
                class: "excellent"
            }
        case "2":
            return {
                color: "#ffeb3b",
                text: "Very Good",
                textAr: "جيد جداً",
                class: "vgood"
            }
        case "3":
            return {
                color: "#ffeb3b",
                text: "Good",
                textAr: "جيد",
                class: "good"
            }
        case "4":
            return {
                color: "#f5ab3d",
                text: "Acceptable",
                textAr: "مقبول",
                class: "accep"
            }
        case "5":
            return {
                color: "#e08f89",
                text: "Weak",
                textAr: "ضعيف",
                class: "week"
            }
        case "6":
            return {
                color: "#f26c62",
                text: "Very Weak",
                textAr: "ضعيف جداً",
                class: "vweek"
            }
    }
}

export const validatePhone = (phone) => {
    // Check regex for phone , else print error message
    const phoneRegex = /^(\+971|00971|0)?5[0245678]\d{7}$/;
    return phoneRegex.test(phone); // true or false

}

export const validateEmail = (email) => {
    // Check regex for email , else print error message
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email); // true or false
}

export async function isNapoStudentHasDraftApp() {
    const userInfo = getUserCookies();
    let result = false;
    if (userInfo) {
        const TahalufNapoURL = process.env.REACT_APP_UAT_API;
        let napoURLParams = "";
        // Check Param [email]
        if (userInfo.email) napoURLParams = `Email=${userInfo.email}`;
        // Check Param [phone]
        if (userInfo.phone) napoURLParams += `&Phone=${userInfo.phone}`;
        // Check Param [fullname]
        if (userInfo.fullname) napoURLParams += `&UserName=${userInfo.fullname}`;
        // Fetch Data
        await fetch(`${TahalufNapoURL}api/TahalufNapo/ChecksIfUserHasNapoDraft?${napoURLParams}`)
            .then((response) => response.json())
            .then((data) => {
                result = data;
            })
            .catch((error) => {
                result = false;
                console.error("Error:", error);
            });
    }
    return result
}

// Handel Open Live Chat
export const handelOpenLiveChat = (e) => {
    e.preventDefault();
    try {
        cxone("chat", "openChatWindow");
    } catch (error) {
        console.log('error in open chat window', error);
    }
}
