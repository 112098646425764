import React from 'react'
import MOELayout from '../../layouts/MOELayout'
import './innerPages.scss';

const InnerPages = ({children, Content, options}) => {

    return (
        <MOELayout
            pageClassName='services-card-page page-content'
            pageTitle={options?.pageTitle || ''}
            pageTitleAr={options?.pageTitleAr || ''}
            titleBanner={{show: true}}
            showBreadCrumb={options?.showBreadCrumb || false}
            showTitleBanner={options?.showTitleBanner || false}
            bannerImage={options?.bannerImage || ''}
            pageDescription={options?.pageDescription || ''}
            pageDescriptionAr={options?.pageDescriptionAr || ''}
        >
            <div className='container'>
                <div className='inner-page-content' >

                    {children && <div dangerouslySetInnerHTML={{__html: children}}></div>}

                    {
                        Content && <div className='moe-sub-content' id='readspeaker-content'>
                            {Content}
                        </div>
                    }
                </div>
            </div>
        </MOELayout>
    )
}

export default InnerPages