import React from 'react'
import MOESelect from '@components/form/MOESelect'
import MOEDatePicker from '@components/form/MOEDatePicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const MOEFormField = ({ data, onChange, errors }) => {
  const { label, labelAr, id, type, placeholder, required, options, value, maxLength } = data;
  const onChangeSelect = (e) => {
    data.value = e.value
    onChange(data)
  }
  const onChangeText = (e) => {
    data.value = e.target.value
    onChange(data)
  }
  const onChangeDate = (e) => {
    data.value = moment(e).format('YYYY-MM-DD')
    onChange(data)
  }
  const renderInputField = () => {
    switch (type) {
      case 'select':
        return <MOESelect id={id} options={options} placeholder={placeholder} onChange={onChangeSelect} />;
      case 'textarea':
        return (
          <textarea
            onChange={onChangeText}
            id={id}
            className="form-control"
            placeholder={placeholder}
            name={id}
          />
        );
      case 'date':
        return (
          <MOEDatePicker
            id={id}
            placeholderText={placeholder}
            selected={value ? new Date(value) : null}
            {...data}
            onChange={onChangeDate}
          />
        );
      case 'tel':
        return (
          <input
            onChange={onChangeText}
            type={type}
            id={id}
            className="form-control"
            placeholder={placeholder}
            maxLength={maxLength}
            name={id}
          />
        );
      default:
        return (
          <input
            onChange={onChangeText}
            type={type}
            id={id}
            className="form-control"
            placeholder={placeholder}
            maxLength={maxLength}

            name={id}
          />
        );
    }
  };

  return (
    <div className="form-group">
      <label htmlFor={id}>
        {isArabic ? labelAr : label} {required && <span className="text-danger">*</span>}
      </label>
      <div className='d-block flex-grow-1'>
        {renderInputField()}
        {errors && errors[id] && (
          <small style={{ color: "red" }}>{errors[id]}</small>
        )}
      </div>
    </div>
  );
};


export default MOEFormField