import React, {lazy, Suspense} from "react";
import InnerPages from "./pages/InnerPages";
import {
    PhotoGalleryPage,
    GovernmentCharterPage,
    VideoGalleryPage,
    NewsPage,
    Initiatives,
    FacilitiesDirectory,
    SchoolsMapPage,
    EServicesPage,
    HomePage,
    ServiceCardPage,
    ContactUsPage,
    AcademicCalendar,
    SurveyResults,
    SurveyPage,
    OpenDataPage,
    DataVisualizationPage,
    InternalAuditPage,
    FAQsPage,
    ImportantLinksPage,
} from "./pages";
import SearchResultPage from "./pages/SearchResultsPage";
import AlumniPartners from "./pages/Alumni/AlumniPartners";
import GlobalAlumni from "./pages/Alumni/GlobalAlumni";
import Skeleton from "react-loading-skeleton";

// Get page content attribute from DOM
const pageContentAttr = $("#moe-content").attr("data-content");
// Get page sub content attribute from DOM
const pageSubContentAttr = $("#moe-sub-content").attr("data-sub-content");
// Check page content attribute and return page content component accordingly
const GetPageContent = () => {
    switch (pageContentAttr) {
        case "home-page":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <HomePage />
                </Suspense>
            );
        case "EServices-page":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <EServicesPage />
                </Suspense>
            );
        case "serviceCard-page":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <ServiceCardPage />
                </Suspense>
            );
        case "contact-us":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <ContactUsPage />
                </Suspense>
            );
        case "news":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <NewsPage />
                </Suspense>
            );
        case "schoolsmap":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <SchoolsMapPage />
                </Suspense>
            );
        case "government-charter":
            return (
                <Suspense fallback={<Skeleton className="government-charter" />}>
                    <GovernmentCharterPage />
                </Suspense>
            );
        case "news-details":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <NewsDetailsPage />
                </Suspense>
            );
        case "academic-calendar":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <AcademicCalendar />
                </Suspense>
            );
        case "survay-results":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <SurveyResults />
                </Suspense>
            );
        case "search-results":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <SearchResultPage />
                </Suspense>
            );
        case "faq":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <FAQsPage />
                </Suspense>
            );
        case "alumni-partners":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <AlumniPartners />
                </Suspense>
            );

        case "global-alumni":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <GlobalAlumni />
                </Suspense>
            );
        case "important-links":
            return (
                <Suspense fallback={<Skeleton className="content-skeleton" />}>
                    <ImportantLinksPage />
                </Suspense>
            );
        case "inner-pages":
            const pageContent = $("#moe-content").html();
            const pageTitle = $("#moe-sub-content").attr("data-page-title") || "";
            const pageTitleAr =
                $("#moe-sub-content").attr("data-page-title-ar") || "";
            const pageShowTitle =
                $("#moe-sub-content").attr("data-show-title") == "true";
            const pageBreadcrumb =
                $("#moe-sub-content").attr("data-breadcrumb") == "true";
            const showBreadCrumb =
                $("#moe-sub-content").attr("data-show-breadcrumb") == "true";
            const showTitleBanner =
                $("#moe-sub-content").attr("data-show-banner") == "true";
            const bannerImage =
                $("#moe-sub-content").attr("data-banner-image") || "";
            const pageDescription =
                $("#moe-sub-content").attr("data-page-description") || "";
            const pageDescriptionAr =
                $("#moe-sub-content").attr("data-page-description-ar") || "";

            const pageOptions = {
                pageTitle,
                pageTitleAr,
                pageShowTitle,
                pageBreadcrumb,
                showBreadCrumb,
                showTitleBanner,
                bannerImage,
                pageDescription,
                pageDescriptionAr,
            };
            switch (pageSubContentAttr) {
                case "photo-gallery":
                    return (
                        <Suspense>
                            <InnerPages
                                options={pageOptions}
                                children={pageContent}
                                Content={<PhotoGalleryPage />}
                            />
                            ;
                        </Suspense>
                    );
                case "video-gallery":
                    return (
                        <Suspense>
                            <InnerPages
                                options={pageOptions}
                                children={pageContent}
                                Content={<VideoGalleryPage />}
                            />
                        </Suspense>
                    );
                case "news-page":
                    return (
                        <Suspense>
                            <InnerPages
                                options={pageOptions}
                                children={pageContent}
                                Content={<NewsPage />}
                            />
                        </Suspense>
                    );
                case "survey-page":
                    return (
                        <Suspense>
                            <InnerPages
                                options={pageOptions}
                                children={pageContent}
                                Content={<SurveyPage />}
                            />
                        </Suspense>
                    );
                case "survay-results":
                    return (
                        <Suspense>
                            <InnerPages
                                options={pageOptions}
                                children={pageContent}
                                Content={<SurveyResults />}
                            />
                        </Suspense>
                    );
                case "openData":
                    return (
                        <Suspense
                            fallback={<Skeleton className="content-skeleton" />}
                        >
                            <OpenDataPage
                                options={pageOptions}
                                children={pageContent}
                                Content={<OpenDataPage />}
                            />
                        </Suspense>
                    );
                case "dataVisualization":
                    return (
                        <Suspense
                            fallback={<Skeleton className="content-skeleton" />}
                        >
                            <DataVisualizationPage
                                options={pageOptions}
                                children={pageContent}
                                Content={<DataVisualizationPage />}
                            />
                        </Suspense>
                    );
                case "internal-audit-page":
                    return (
                        <Suspense
                            fallback={<Skeleton className="content-skeleton" />}
                        >
                            <InternalAuditPage
                                options={pageOptions}
                                children={pageContent}
                                Content={<InternalAuditPage />}
                            />
                        </Suspense>
                    );

                case "initiatives-page":
                    return (
                        <Suspense
                            fallback={<Skeleton className="content-skeleton" />}
                        >
                            <Initiatives
                                options={pageOptions}
                                children={pageContent}
                                Content={<NewsPage />}
                            />
                        </Suspense>
                    );
                case "facilities-directory":
                    return (
                        <Suspense
                            fallback={<Skeleton className="content-skeleton" />}
                        >
                            <FacilitiesDirectory />
                        </Suspense>
                    );
                default:
                    return <InnerPages options={pageOptions} children={pageContent} />;
            }
        case "404":
            return <NotFoundPage />;
        default:
            return <div />;
    }
};
const App = () => {

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        // fadeout , them remove loader
        setLoading(false);
    }, []);
    return <GetPageContent />;
};

export default App;
