import React from 'react'
import MOEBreadCrumb from '@components/MOEBreadCrumb'
import MOEModal from '@components/MOEModal'
import MOETitleBanner from '../components/MOETitleBanner'

const MOELayout = ({children, pageClassName, pageTitle, pageTitleAr, showBreadCrumb, showTitleBanner, bannerImage, loading, pageDescription, pageDescriptionAr}) => (
    <div className={`moe-layout ${isLive || '-container -py-5'} ${pageClassName ? pageClassName : pageTitle?.split(' ').join('-').toLowerCase() + '-page' || ''} page-wrapper`}>
        {/* <div className={`moe-layout ${isLive || 'container py-5'} ${pageClassName || `${pageTitle?.split(' ').join('-').toLowerCase()}-page` || ''} page-wrapper`}> */}
        {/* <div className={`moe-layout  ${pageClassName ? pageClassName : pageTitle?.split(' ').join('-').toLowerCase() + '-page' || ''} page-wrapper`}> */}

        {
            showTitleBanner && (
                <div className='container'>
                    <div className={`page-title-banner ${showBreadCrumb ? 'breadcrumb-is-visible' : ''} ${showTitleBanner ? 'title-banner-is-visible' : ''}`}>
                        <div className={`d-flex flex-column title-banner-container ${bannerImage ? 'mb-5' : ''}`}>

                            {showBreadCrumb && <MOEBreadCrumb currentPage={isArabic ? pageTitleAr : pageTitle} />}

                            {
                                showTitleBanner &&

                                <MOETitleBanner
                                    image={bannerImage}
                                    title={isArabic ? pageTitleAr : pageTitle}
                                    description={isArabic ? pageDescriptionAr : pageDescription}
                                    loading={loading}
                                />
                            }
                        </div>
                    </div>
                </div>
            )
        }

        <div className='page-content'>
            {children}
        </div>
        <MOEModal />

    </div >
)

export default MOELayout