import {ClockIcon, ConditionsIcon, FileIcon, FolderIcon, MoneyIcon, StepsIcon} from '../../components/MOEIcon';
import {getServicesByPageName, getServicesCards} from '../../api/services.api';

export const START_LOADING_GET_SERVICE_CARD = 'START_LOADING_GET_SERVICE_CARD';
export const SERVICE_CARD_SUCCESS = 'SERVICE_CARD_SUCCESS';
export const SERVICE_CARD_FAIL = 'SERVICE_CARD_FAIL';
export const SERVICE_INFO_BOX = 'SERVICE_INFO_BOX';
export const SERVICE_CARD_INFO_LIST = 'SERVICE_CARD_INFO_LIST';

export const getServiceCardData = (pageName) => async (dispatch) => {
    dispatch({type: START_LOADING_GET_SERVICE_CARD});
    try {
        const liveURL = `/${isArabic ? 'ar' : 'en'}/_api/web/lists/getbytitle('Service Cards')/items?$filter=ServicePageName eq '${pageName}'`


        const {data} = await getServicesByPageName(pageName)
        const results = data.value[0]

        dispatch({type: SERVICE_CARD_SUCCESS, payload: results})
        // ==============================
        // Get Info Box From Results
        // ==============================
        var {Desc_x002d_time, Desc_x002d_time, Desc_x002d_fee, Desc_x002d_fee, Desc_x002d_doc, Desc_x002d_doc} = results;

        let infoBox = [
            {
                icon: ClockIcon,
                text: isArabic ? Desc_x002d_time : Desc_x002d_time,
            },
            {
                icon: MoneyIcon,
                text: isArabic ? Desc_x002d_fee : Desc_x002d_fee,
            },
            {
                icon: FileIcon,
                text: isArabic ? Desc_x002d_doc : Desc_x002d_doc,
            }
        ];

        dispatch({type: SERVICE_INFO_BOX, payload: infoBox});

        // ==============================
        // Set Service Card Information
        // ==============================
        let {Steps, Conditions, Documents, Fees, ServiceTime, WorkAddress, RelatedContent} = results
        let serviceData = [
            {
                id: 1,
                title: isArabic ? "الخطوات" : "Steps",
                icon: StepsIcon,
                content: Steps,
            },
            {
                id: 2,
                title: isArabic ? "الشروط" : "Conditions",
                icon: ConditionsIcon,
                content: Conditions,
            },
            {
                id: 3,
                title: isArabic ? "المستندات المطلوبة" : "Required documents",
                icon: FileIcon,
                content: Documents,
            },
            {
                id: 4,
                title: isArabic ? "الرسوم" : "Fees",
                icon: MoneyIcon,
                content: Fees,
            },
            {
                id: 5,
                title: isArabic ? "وقت الخدمة" : "Service time",
                icon: ClockIcon,
                content: ServiceTime,
            },
            {
                id: 6,
                title: isArabic ? "العنوان ومعلومات أخرى" : "Address and other information",
                icon: FolderIcon,
                content: WorkAddress,
            },
            {
                id: 7,
                title: isArabic ? "قنوات التقديم على الخدمة " : "Service Channels",
                icon: FileIcon,
                content: RelatedContent,

            },
        ]

        dispatch({type: SERVICE_CARD_INFO_LIST, payload: serviceData})

    }
    catch (error) {
        console.log('error', error)
        dispatch({type: SERVICE_CARD_FAIL, payload: error})
    }
};