const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
const GET_FILTERED_SERVICES = 'GET_FILTERED_SERVICES';
const GET_FILTERED_CATEGORIES = 'GET_FILTERED_CATEGORIES';
const GET_SINGLE_SERVICE = 'GET_SINGLE_SERVICE';
const GET_SERVICES_RECENT = 'GET_SERVICES_RECENT';
const GET_SERVICES_POPULAR = 'GET_SERVICES_POPULAR';
const GET_SERVICES_ALL_CATEGORY = 'GET_SERVICES_ALL_CATEGORY';
const GET_SERVICES_SINGLE_CATEGORY = 'GET_SERVICES_SINGLE_CATEGORY';
const GET_SERVICES_CATEGORY_RECENT = 'GET_SERVICES_CATEGORY_RECENT';
const GET_SERVICES_CATEGORY_USED = 'GET_SERVICES_CATEGORY_USED';
const GET_SERVICES_CATEGORY_INDIVIDUAL = 'GET_SERVICES_CATEGORY_INDIVIDUAL';
const GET_SERVICES_CATEGORY_CORPORATES = 'GET_SERVICES_CATEGORY_CORPORATES';
const SET_CATEGORY_ACTIVE = 'SET_CATEGORY_ACTIVE';
const SET_SERVICE_FILTER_KEYWORD = 'SET_SERVICE_FILTER_KEYWORD';
const GET_SEARCHED_SERVICES = 'GET_SEARCHED_SERVICES'
const CHECK_STATUS = 'CHECK_STATUS';
const CHECK_STATUS_SUCCESS = 'CHECK_STATUS_SUCCESS';
const CHECK_STATUS_ERROR = 'CHECK_STATUS_ERROR';
const CLOSE_MODAL = 'CLOSE_MODAL'
const SET_LOADING = 'SET_LOADING';
const SET_ERROR = 'SET_ERROR';
const SET_SUCCESS = 'SET_SUCCESS';
const SET_ACCESSABILITY_ACTIVE = 'SET_ACCESSABILITY_ACTIVE';
const SET_SIDE_MENU_ACTIVE = 'SET_SIDE_MENU_ACTIVE';
const CLOSE_ACCESSABILITY = 'CLOSE_ACCESSABILITY';
const TOGGLE_ACCESSABILITY = 'TOGGLE_ACCESSABILITY';
const ACCESSABILITY_CHANGE = 'ACCESSABILITY_CHANGE';
const ACCESSABILITY_CHANGE_THEME = 'ACCESSABILITY_CHANGE_THEME';
const SET_SELECTED_FILTER_ACTIVE = 'SET_SELECTED_FILTER_ACTIVE';
const SET_FILTERING_ITEMS_BY = 'SET_FILTERING_ITEMS_BY';
const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';

const SET_PRE_ASSESSMENTـOPTIONS = "SET_PRE_ASSESSMENTـOPTIONS";
const SET_PRE_ASSESSMENT_VALUE = "SET_PRE_ASSESSMENT_VALUE";
const SET_PRE_ASSESSMENT_ERROR = "SET_PRE_ASSESSMENT_ERROR";
const SET_PRE_ASSESSMENTـLOOKUPS = "SET_PRE_ASSESSMENTـLOOKUPS";

const SET_PRE_ASSESSMENT_FORM_VALID = "SET_PRE_ASSESSMENT_FORM_VALID";
const SET_PRE_ASSESSMENT_FORM_SUBMITTED = "SET_PRE_ASSESSMENT_FORM_SUBMITTED";
const SET_PRE_ASSESSMENT_FORM_SUBMITTING = "SET_PRE_ASSESSMENT_FORM_SUBMITTING";
const SET_PRE_ASSESSMENT_FORM_SUBMIT_SUCCESS = "SET_PRE_ASSESSMENT_FORM_SUBMIT_SUCCESS";
const SET_PRE_ASSESSMENT_FORM_SUBMIT_ERROR = "SET_PRE_ASSESSMENT_FORM_SUBMIT_ERROR";

const GET_SCHOOLS_START = 'GET_SCHOOLS_START';
const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS';
const GET_SCHOOLS_FAIL = 'GET_SCHOOLS_FAIL';
const GET_SINGLE_SCHOOL_START = 'GET_SINGLE_SCHOOL_START';
const GET_SINGLE_SCHOOL_SUCCESS = 'GET_SINGLE_SCHOOL_SUCCESS';
const GET_SINGLE_SCHOOL_FAIL = 'GET_SINGLE_SCHOOL_FAIL';
const OPEN_SCHOOL_MODAL = 'OPEN_MODAL';
const CLOSE_SCHOOL_MODAL = 'CLOSE_MODAL';
const SET_SCHOOL_PAGE = 'SET_SCHOOL_PAGE';
const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
const SET_SCHOOLS_FILTERS = 'SET_SCHOOLS_FILTERS';
const GET_FILTERED_SCHOOLS = 'GET_FILTERED_SCHOOLS';
const Reset_SCHOOLS_FILTERS = 'Reset_SCHOOLS_FILTERS';

const SET_NAV_MENU = 'SET_NAV_MENU';

// Contact US 
const GET_BRANCHES_LIST = 'GET_BRANCHES_LIST';
const CONTACT_US_LOADING = 'CONTACT_US_LOADING';
const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
const CONTACT_US_RES = 'CONTACT_US_RES';
const CONTACT_US_ERROR = 'CONTACT_US_ERROR';
const CONTACT_US_RESET = 'CONTACT_US_RESET';
const CONTACT_US_FORM_FIELD_CHANGE = 'CONTACT_US_FORM_FIELD_CHANGE';
const CONTACT_US_FORM_VALID = 'CONTACT_US_FORM_VALID';
const CONTACT_US_RECAPTCHA = 'CONTACT_US_RECAPTCHA';
const CONTACT_US_RESET_FORM = 'CONTACT_US_RESET_FORM';
const CHECK_FORM_VALIDATION = 'CHECK_FORM_VALIDATION';
const BRANCHES_LIST_ERROR = 'BRANCHES_LIST_ERROR';

export {
    TOGGLE_MOBILE_MENU,
    SET_FILTERING_ITEMS_BY,
    SET_SELECTED_FILTER_ACTIVE,
    ACCESSABILITY_CHANGE_THEME,
    CLOSE_ACCESSABILITY,
    TOGGLE_ACCESSABILITY,
    ACCESSABILITY_CHANGE,
    SET_LOADING,
    SET_ERROR,
    SET_SUCCESS,
    SET_ACCESSABILITY_ACTIVE,
    SET_SIDE_MENU_ACTIVE,
    SET_CATEGORY_ACTIVE,
    SET_SERVICE_FILTER_KEYWORD,
    GET_ALL_SERVICES,
    GET_SEARCHED_SERVICES,
    GET_FILTERED_SERVICES,
    GET_FILTERED_CATEGORIES,
    GET_SINGLE_SERVICE,
    GET_SERVICES_RECENT,
    GET_SERVICES_POPULAR,
    GET_SERVICES_ALL_CATEGORY,
    GET_SERVICES_SINGLE_CATEGORY,
    GET_SERVICES_CATEGORY_RECENT,
    GET_SERVICES_CATEGORY_USED,
    GET_SERVICES_CATEGORY_INDIVIDUAL,
    GET_SERVICES_CATEGORY_CORPORATES,
    CHECK_STATUS,
    CHECK_STATUS_SUCCESS,
    CHECK_STATUS_ERROR,
    CLOSE_MODAL,
    SET_PRE_ASSESSMENTـOPTIONS,
    SET_PRE_ASSESSMENT_VALUE,
    SET_PRE_ASSESSMENTـLOOKUPS,
    SET_PRE_ASSESSMENT_ERROR,
    SET_PRE_ASSESSMENT_FORM_VALID,
    SET_PRE_ASSESSMENT_FORM_SUBMITTED,
    SET_PRE_ASSESSMENT_FORM_SUBMITTING,
    SET_PRE_ASSESSMENT_FORM_SUBMIT_SUCCESS,
    SET_PRE_ASSESSMENT_FORM_SUBMIT_ERROR,
    SET_NAV_MENU,

    GET_SCHOOLS_START,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_FAIL,
    GET_SINGLE_SCHOOL_START,
    GET_SINGLE_SCHOOL_SUCCESS,
    GET_SINGLE_SCHOOL_FAIL,
    OPEN_SCHOOL_MODAL,
    CLOSE_SCHOOL_MODAL,
    SET_SCHOOL_PAGE,
    SET_SEARCH_VALUE,
    SET_SCHOOLS_FILTERS,
    GET_FILTERED_SCHOOLS,
    Reset_SCHOOLS_FILTERS,

    // Contact Us
    GET_BRANCHES_LIST,
    CONTACT_US_LOADING,
    CONTACT_US_SUCCESS,
    CONTACT_US_RES,
    CONTACT_US_ERROR,
    CONTACT_US_RESET,
    CONTACT_US_FORM_FIELD_CHANGE,
    CONTACT_US_FORM_VALID,
    CONTACT_US_RECAPTCHA,
    CONTACT_US_RESET_FORM,
    CHECK_FORM_VALIDATION,
    BRANCHES_LIST_ERROR
}