import { GET_ALL_SERVICES, GET_SINGLE_SERVICE, GET_SERVICES_ALL_CATEGORY, GET_FILTERED_CATEGORIES, GET_SEARCHED_SERVICES, SET_SELECTED_FILTER_ACTIVE, SET_FILTERING_ITEMS_BY } from '../actions/index';
import { SET_NABO_SERVICE_MODAL } from '../actions/servicesActions';

const initialState = {
    ItemsPerPage: '',
    filterKeywords: 'all',
    naboServiceModal: false,
    services: [],
    filterServicesBy: 'all', // 'all', 'recent', 'popular', 'corporates', 'individuals
    servicesList: [],
    singleService: {},
    categories: [],
    initCategory: {
        Title: isArabic ? 'كل الخدمات' : 'All Services',
        ID: 'all',
        active: true,
    },
    categoriesList: [],
    serviceFilterBar: [
        {
            id: 1,
            slug: 'all',
            title: isArabic ? 'كل الخدمات' : 'All Services',
            active: true
        },
        {
            id: 2,
            slug: 'corporates',
            title: isArabic ? 'الشركات' : 'Corporates',
            active: false
        },
        {
            id: 3,
            slug: 'individuals',
            title: isArabic ? 'الأفراد' : 'Individuals',
            active: false
        },
        {
            id: 4,
            slug: 'popular',
            title: isArabic ? 'الخدمات الشائعة' : 'Popular Services',

            active: false
        },
    ],
    categoriesFilterBar: [
        {
            id: 1,
            slug: 'all',
            title: isArabic ? 'كل الفئات' : 'All Categories',
            active: true
        },
        {
            id: 2,
            slug: (isArabic ? "خدمات المؤسسات" : "Organizations Services").toLowerCase().replace(" ", "-"),
            title: isArabic ? "خدمات المؤسسات" : "For Corporates",
            active: false
        },
        {
            id: 3,
            slug: (isArabic ? "خدمات الأفراد" : "Individuals Services").toLowerCase().replace(" ", "-"),
            title: isArabic ? 'خدمات الأفراد' : 'For Individuals',
            active: false
        },
        {
            id: 4,
            slug: 'popular-services',
            title: isArabic ? 'الخدمات الشائعة' : 'Popular Services',
            active: false
        },
    ],
    loading: true,
    error: null,
};

export const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERING_ITEMS_BY:
            return {
                ...state,
                filterServicesBy: action.payload,
            }
        case GET_ALL_SERVICES:
            return {
                ...state,
                services: action.payload,
                servicesList: action.payload,
                loading: false,
            }
        case GET_SERVICES_ALL_CATEGORY:
            return {
                ...state,
                categories: [state.initCategory, ...action.payload],
                categoriesList: [state.initCategory, ...action.payload],
                loading: false,
            }
        case GET_SEARCHED_SERVICES:
            return {
                ...state,
                filterServicesBy: 'all',
                servicesList: (function () {
                    switch (state.filterKeywords) {
                        case 'popular':
                            return state.services.map((item) => item.MostUsed.toLowerCase() != "no" ? item : null).filter((item) => item != null).filter((item) => item.ServiceName.toLowerCase().includes(action.payload.toLowerCase()))
                        case 'corporates':
                            return state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات المؤسسات" : "Organizations Services") ? item : null).filter((item) => item != null).filter((item) => item.ServiceName.toLowerCase().includes(action.payload.toLowerCase()))
                        case 'individuals':
                            return state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات الأفراد" : "Individuals Services") ? item : null).filter((item) => item != null).filter((item) => item.ServiceName.toLowerCase().includes(action.payload.toLowerCase()))
                        default:
                            return state.services.filter((item) => item.ServiceName.toLowerCase().includes(action.payload.toLowerCase()))
                    }
                })(),

                categoriesList: (function () {
                    // set all categories to active, and then filter them based on search keyword item.Title
                    return state.categories.map((item) => {
                        return item.ID == 'all' ? { ...item, active: true } : { ...item, active: false }
                    })
                        .filter((item) => {
                            return item.ID !== 'all' ? item.Title.toLowerCase().includes(action.payload.toLowerCase()) : item
                        })
                })()

            }

        case GET_FILTERED_CATEGORIES:
            // payload should be categoryID, then filter categoriesList and servicesList based on categoryID
            return {
                ...state,
                categoriesList: state.categoriesList.map((item) => item.ID == action.payload ? { ...item, active: true } : { ...item, active: false }),
                servicesList: (function () {
                    switch (state.filterKeywords) {
                        case 'popular':
                            return action.payload == 'all' ? state.services.map((item) => item.MostUsed.toLowerCase() != "no" ? item : null).filter((item) => item != null) : state.services.map((item) => item.MostUsed.toLowerCase() != "no" ? item : null).filter((item) => item != null).map((item) => item.ServicCatalogId == action.payload ? item : null).filter((item) => item != null)
                        case 'corporates':
                            return action.payload == 'all' ? state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات المؤسسات" : "Organizations Services") ? item : null).filter((item) => item != null) : state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات المؤسسات" : "Organizations Services") ? item : null).filter((item) => item != null).map((item) => item.ServicCatalogId == action.payload ? item : null).filter((item) => item != null)
                        case 'individuals':
                            return action.payload == 'all' ? state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات الأفراد" : "Individuals Services") ? item : null).filter((item) => item != null) : state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات الأفراد" : "Individuals Services") ? item : null).filter((item) => item != null).map((item) => item.ServicCatalogId == action.payload ? item : null).filter((item) => item != null)
                        default:
                            return action.payload == 'all' ? state.services : state.services.map((item) => item.ServicCatalogId == action.payload ? item : null).filter((item) => item != null)
                    }
                })()

            }
        case SET_SELECTED_FILTER_ACTIVE:
            const { filterBarType, filterBy } = action.payload;
            switch (filterBarType) {
                case 'service':
                    return {
                        ...state,
                        filterKeywords: filterBy,
                        serviceFilterBar: state.serviceFilterBar.map(item => item.slug == filterBy ? { ...item, active: true } : { ...item, active: false }),
                        servicesList: (function () {
                            switch (filterBy) {
                                case 'all':
                                    return state.services
                                case 'popular':
                                    return state.services.map((item) => item.MostUsed.toLowerCase() != "no" ? item : null).filter((item) => item != null)
                                case 'corporates':
                                    return state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات المؤسسات" : "Organizations Services") ? item : null).filter((item) => item != null)
                                case 'individuals':
                                    return state.services.map((item) => item.ServiceLevel == (isArabic ? "خدمات الأفراد" : "Individuals Services") ? item : null).filter((item) => item != null)
                                default:
                                    return state.services
                            }
                        })(),
                        // Set Categories Filter Bar active to 'all'
                        categoriesFilterBar: filterBy == 'all' ? state.categoriesFilterBar.map((item) => item.slug == 'all' ? { ...item, active: true } : { ...item, active: false }) : state.categoriesFilterBar,
                        // get all categories that equal
                        categoriesList: (function () {
                            switch (filterBy) {
                                case 'all':
                                    return state.categories
                                case 'popular':
                                    return [state.initCategory, ...state.categories.filter((item) => {
                                        const services = state.services.map((item) => item.MostUsed.toLowerCase() != "no" ? item : null).filter((item) => item != null);
                                        return services.map((item) => item.ServicCatalogId).includes(item.ID)
                                    })]
                                case 'corporates':
                                    return [state.initCategory, ...state.categories.map((item) => item.Level == (isArabic ? "خدمات المؤسسات" : "Organizations Services") ? item : null).filter((item) => item != null)]
                                case 'individuals':
                                    return [state.initCategory, ...state.categories.map((item) => item.Level == (isArabic ? "خدمات الأفراد" : "Individuals Services") ? item : null).filter((item) => item != null)]
                                default:
                                    return [state.initCategory, ...state.categories]
                            }
                        })(),
                    }
                case 'category':
                    return {
                        ...state,
                        filterKeywords: filterBy,
                        categoriesFilterBar: state.categoriesFilterBar.map((item) => {
                            // detect [filterBy] value and set it to active
                            return item.slug == filterBy ? { ...item, active: true } : { ...item, active: false }
                        }),
                        categoriesList: (function () {
                            switch (filterBy) {
                                case 'all' || 'popular-services':
                                    return state.categories
                                default:
                                    return state.categories.map((item) => {
                                        return item?.Level?.toLowerCase().replace(' ', '-') == filterBy ? item : null
                                    }).filter((item) => item != null)
                            }
                        })(),
                        servicesList: (function () {
                            switch (filterBy) {
                                case 'popular-services':
                                    state.services.map((item) => item.MostUsed.toLowerCase() != "no" ? item : null).filter((item) => item != null)
                                default:
                                    return state.services
                            }
                        })(),
                    }
                default:
                    return state;
            }

        case GET_SINGLE_SERVICE:
            return {
                ...state,
                singleService: action.payload,
                loading: false,
            }
        case SET_NABO_SERVICE_MODAL:
            return {
                ...state,
                naboServiceModal: action.payload
            }

        default:
            return state;
    }
}