import React from 'react';

export const HomePage = React.lazy(() => import('./HomePage'));
export const ServiceCardPage = React.lazy(() => import('./ServiceCardPage'));
export const EServicesPage = React.lazy(() => import('./EServicesPage'));
export const ContactUsPage = React.lazy(() => import('./ContactUsPage'));
export const AcademicCalendar = React.lazy(() => import('./AcademicCalendar​'));
export const SurveyResults = React.lazy(() => import('./SurveyResults'));
export const SurveyPage = React.lazy(() => import('./Survey'));
export const OpenDataPage = React.lazy(() => import('./OpenData'));
export const DataVisualizationPage = React.lazy(() => import('./DataVisualization'));
export const InternalAuditPage = React.lazy(() => import('./InternalAudit'));
export const PhotoGalleryPage = React.lazy(() => import('./PhotoGallery'));
export const GovernmentCharterPage = React.lazy(() => import('./GovernmentCharter'));
export const VideoGalleryPage = React.lazy(() => import('./VideoGallery'));
export const NewsPage = React.lazy(() => import('./NewsPage'));
export const Initiatives = React.lazy(() => import('./InnerPages/Initiatives'));
export const FacilitiesDirectory = React.lazy(() => import('./FacilitiesDirectory'));
export const SchoolsMapPage = React.lazy(() => import('./Schoolsmap'));
export const SearchResultsPage = React.lazy(() => import('./SearchResultsPage'));
export const FAQsPage = React.lazy(() => import('./FAQs'));
export const ImportantLinksPage = React.lazy(() => import('./ImportantLinksPage'));
