import { validateEmail, validatePhone } from "../../helper";
import {
  BRANCHES_LIST_ERROR,
  CHECK_FORM_VALIDATION,
  CONTACT_US_ERROR,
  CONTACT_US_FORM_FIELD_CHANGE,
  CONTACT_US_FORM_VALID,
  CONTACT_US_LOADING,
  CONTACT_US_RECAPTCHA,
  CONTACT_US_RESET,
  CONTACT_US_RESET_FORM,
  CONTACT_US_SUCCESS,
  CONTACT_US_RES,
  GET_BRANCHES_LIST,
} from "../actions";

const initalContacFields = {
  loading: false,
  recaptcha: { result: false, error: null },
  formFields: {
    requestTypeId: {
      id: "requestTypeId",
      type: "select",
      name: "requestTypeId",
      placeholder: isArabic ? "اختر احد الخيارات" : "Select one of the options",
      options: [
        {
          label: isArabic ? "استفسار" : "Inquiry",
          value: 2,
        },
        {
          label: isArabic ? "شكر وتقدير" : "Appreciation",
          value: 4,
        },
      ],
      label: isArabic ? "النوع" : "Type",
      error: null,
      value: "",
    },
    fullName: {
      id: "fullName",
      name: "fullName",
      type: "text",
      label: isArabic ? "الاسم الكامل" : "Full Name",
      placeholder: isArabic ? "ادخل اسمك الكامل" : "Enter your name",
      error: null,
      value: "",
    },
    phone: {
      id: "phone",
      name: "phone",
      type: "text",
      label: isArabic ? "رقم الهاتف" : "Phone",
      placeholder: isArabic ? "ادخل رقم الهاتف" : "Enter your phone",
      error: null,
      value: "",
    },
    email: {
      id: "email",
      name: "email",
      type: "email",
      label: isArabic ? "البريد الالكتروني" : "Email Address",
      placeholder: isArabic
        ? "ادخل البريد الالكتروني"
        : "Enter your email address",
      error: null,
      value: "",
    },
    // subject: {
    //     id: 'subject',
    //     type: 'text',
    //     name: 'subject',
    //     placeholder: isArabic ? 'ادخل الموضوع' : 'Enter the subject',
    //     label: isArabic ? 'الموضوع' : 'Subject',
    //     error: null,
    //     value: ''
    // },
    message: {
      id: "message",
      name: "message",
      type: "textarea",
      label: isArabic ? "الرسالة" : "Message",
      placeholder: isArabic ? "ادخل رسالتك" : "Enter your message",
      error: null,
      value: "",
    },
  },
  branches: [],
  isValid: false,
  success: false,
  error: null,
};

export const contactReducer = (state = initalContacFields, action) => {
  switch (action.type) {
    case GET_BRANCHES_LIST:
      return {
        ...state,
        branches: action.payload,
      };
    case BRANCHES_LIST_ERROR:
      return {
        ...state,
        branches: {
          ...state.branches,
          error: action.payload,
        },
      };
    case CONTACT_US_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
    case CONTACT_US_RES:
      return {
        ...state,
        response: action.payload,
      };
    case CONTACT_US_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    case CONTACT_US_FORM_FIELD_CHANGE:
      return {
        ...state,
        formFields: {
          ...state.formFields,
          [action.payload.name]: {
            ...state.formFields[action.payload.name],
            value: action.payload.value || "",
            error: action.payload.error || null,
          },
        },
      };
    case CHECK_FORM_VALIDATION:
      const fields = {};
      Object.keys(state.formFields).forEach((key) => {
        const field = state.formFields[key];
        if (field.value === "") {
          field.error = isArabic ? "هذا الحقل مطلوب" : "This field is required";
          fields[key] = field;
        } else if (key == "email" && !validateEmail(field.value)) {
          field.error = isArabic
            ? "يرجى ادخال بريد إلكتروني صحيح"
            : "Please enter a valid email address.";
          fields[key] = field;
        } else if (key == "phone" && !validatePhone(field.value)) {
          field.error = isArabic
            ? "يرجى ادخال رقم هاتف صحيح"
            : "Please enter a valid phone number";
          fields[key] = field;
        } else {
          field.error = null;
          fields[key] = field;
        }
      });

      return {
        ...state,
        formFields: fields,
      };

    case CONTACT_US_RECAPTCHA:
      return {
        ...state,
        recaptcha: {
          ...state.recaptcha,
          result: action.payload.result,
          error: action.payload.error,
        },
      };
    case CONTACT_US_RESET_FORM:
      return {
        ...state,
        formFields: {
          ...state.formFields,
          requestTypeId: {
            ...state.formFields.requestTypeId,
            value: "",
          },
          fullName: {
            ...state.formFields.fullName,
            value: "",
          },
          phone: {
            ...state.formFields.phone,
            value: "",
          },
          email: {
            ...state.formFields.email,
            value: "",
          },
          message: {
            ...state.formFields.message,
            value: "",
          },
        },
      };
    default:
      return state;
  }
};
