import { CHECK_STATUS, CHECK_STATUS_SUCCESS, CHECK_STATUS_ERROR, CLOSE_MODAL } from '../actions/index';

const initialState = {
    showModal: false,
    status: null,
    loading: false,
    error: null,
    result: null
};


export const checkStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_STATUS:
            return {
                ...state,
                error: null,
                loading: true,
            }
        case CHECK_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.payload.result,
                status: action.payload.status,
                showModal: true,
                showService : action.payload.result.length > 1.
            }
        case CHECK_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: action.payload.status,
            }

        case CLOSE_MODAL:
            return {
                ...state,
                showModal: false
            }
        case 'TOGGLE_MODAL':
            return {
                ...state,
                showModal: !state.showModal
            }
        default:
            return state
    }
}