import {SET_LOADING, TOGGLE_MOBILE_MENU, SET_ERROR, SET_SUCCESS, SET_ACCESSABILITY_ACTIVE, SET_SIDE_MENU_ACTIVE, SET_NAV_MENU} from '../actions'

const initialState = {
    loading: false,
    error: null,
    success: null,
    accessabilityActive: false,
    sideMenuActive: false,
    isArabic: false,
    mobileMenu: false,
    navMenu: {mainMenu: [], sideMenu: []}
}

const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload
            }
        case SET_ACCESSABILITY_ACTIVE:
            return {
                ...state,
                accessabilityActive: action.payload
            }
        case SET_SIDE_MENU_ACTIVE:
            return {
                ...state,
                sideMenuActive: action.payload
            }
        case 'CHANGE_LANGUAGE':
            return {
                ...state,
                isArabic: !isArabic
            }
        case TOGGLE_MOBILE_MENU:
            return {
                ...state,
                mobileMenu: !state.mobileMenu
            }
        case SET_NAV_MENU:
            return {
                ...state,
                navMenu: action.payload
            }
        default:
            return state
    }
}

export default generalReducer