import { GET_SCHOOLS_START, GET_SCHOOLS_SUCCESS, GET_SCHOOLS_FAIL, GET_SINGLE_SCHOOL_START, GET_SINGLE_SCHOOL_SUCCESS, GET_SINGLE_SCHOOL_FAIL, OPEN_SCHOOL_MODAL, CLOSE_SCHOOL_MODAL, SET_SCHOOL_PAGE, SET_SEARCH_VALUE, SET_SCHOOLS_FILTERS, GET_FILTERED_CATEGORIES, GET_FILTERED_SCHOOLS, Reset_SCHOOLS_FILTERS } from '../actions';

const initialState = {
    loading: false,
    error: null,
    singlSchool: {},
    modalOpen: false,
    filters: {},
    selectedFilters: {},
    page: 1,
    itemsPerPage: 12,
    orderBy: 'title',
    data: [],
    schoolsList: [],
}

const schoolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCHOOLS_START:
            return {
                ...state,
                loading: true,
            }
        case SET_SCHOOL_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        case GET_SCHOOLS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                schoolsList: action.payload,
            }
        case SET_SEARCH_VALUE:
            return {
                ...state,
                schoolsList: state.data.filter(item => item[isArabic ? 'NAME_x002d_AR' : 'NAME_x002d_EN'].toLowerCase().includes(action.payload.toLowerCase())),
            }

        case GET_SCHOOLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case GET_SINGLE_SCHOOL_START:
            return {
                ...state,
                loading: true,
            }
        case GET_SINGLE_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                singlSchool: action.payload,
            }
        case GET_SINGLE_SCHOOL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case OPEN_SCHOOL_MODAL:
            return {
                ...state,
                modalOpen: true,
            }
        case CLOSE_SCHOOL_MODAL:
            return {
                ...state,
                modalOpen: false,
            }
        case SET_SCHOOLS_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...action.payload },
            }
        case GET_FILTERED_SCHOOLS:
            return {
                ...state,
                selectedFilters: action.payload,
                schoolsList: (function () {

                    // distructure the filters object
                    let { schoolTypes, emirates, grade, curriculum } = action.payload;

                    // filter the data based on the selected filters
                    let filteredData = state.data.filter((item) => {
                        let Fees = Array.isArray(JSON.parse(item.FEE)) ? JSON.parse(item.FEE) : [];
                        let selected_schoolType = schoolTypes ? schoolTypes.includes(item.SCHOOLTYPE) : true;
                        let selected_emirate = emirates ? emirates.includes(item.EMIRATE) : true;
                        let selected_grade = grade ? (Fees ? Fees.filter(fee => fee.GradeID == grade).length > 0 : false) : true;
                        let selected_curriculum = curriculum ? item.CURRICULUMId == curriculum : true;

                        // only if all the filters are selected it will return the filtered data
                        return selected_schoolType && selected_emirate && selected_grade && selected_curriculum;
                    });

                    return filteredData;
                }())

            }
        case Reset_SCHOOLS_FILTERS:
            return {
                ...state,
                selectedFilters: {},
                schoolsList: state.data,
            }

        default:
            return state;
    }
}

export default schoolsReducer;